import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlohaCardComponent } from './components/aloha-card/aloha-card.component';
import { BuyAlohaEnvelopesComponent } from './components/buy-aloha-envelopes/buy-aloha-envelopes.component';
import { BuyAlohaNewPackComponent } from './components/buy-aloha-new-pack/buy-aloha-new-pack.component';
import { BuyPacksComponent } from './components/buy-packs/buy-packs.component';
import { CardComponent } from './components/card/card.component';
import { CollectionComponent } from './components/collection/collection.component';
import { ExploreComponent } from './components/explore/explore.component';
import { HomeComponent } from './components/home/home.component';
import { LandingComponent } from './components/landing/landing.component';
import { LiquidityEventComponent } from './components/liquidity-event/liquidity-event.component';
import { LotteryComponent } from './components/lottery/lottery.component';
import { NftComponent } from './components/nft/nft.component';
import { PoolComponent } from './components/pool/pool.component';
import { StakeComponent } from './components/stake/stake.component';
import { SurfRidersComponent } from './components/surf-riders/surf-riders.component';
import { WhatsHotComponent } from './components/whats-hot/whats-hot.component';
import { NoCommaPipe } from './pipes/no-comma.pipe';

@NgModule({
  declarations: [
    AppComponent,
    NoCommaPipe,
    SurfRidersComponent,
    CollectionComponent,
    StakeComponent,
    ExploreComponent,
    PoolComponent,
    AlohaCardComponent,
    CardComponent,
    HomeComponent,
    LandingComponent,
    NftComponent,
    LiquidityEventComponent,
    BuyAlohaEnvelopesComponent,
    BuyAlohaNewPackComponent,
    WhatsHotComponent,
    LotteryComponent,
    BuyPacksComponent,
  ],
  imports: [CommonModule, BrowserModule, AppRoutingModule, HttpClientModule],
  providers: [Meta,
    {provide: 'googleTagManagerId',  useValue: 'AW-961083744'}],
  bootstrap: [AppComponent],
})
export class AppModule {}
