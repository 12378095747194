<section>
  <div class="container">
    <div
      *ngIf="loading && isMetamaskConnected && isPolygon"
      class="row justify-content-md-center"
    >
      <div class="col-md-12 col-xl-12">
        <div class="d-flex ht-300 pos-relative align-items-center">
          <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row p-b-30">
      <div class="col-lg-12 center">
        <h2>Lucky NFT pack 10k ALOHA, connect wallet to purchase!</h2>
      </div>
    </div>

    <div *ngIf="!isPolygon" class="row p-b-30">
      <div class="col-lg-12 center">
        <h2>Connect to Polygon first!</h2>
      </div>
    </div>

    <div *ngIf="opened" class="row p-b-30 col-12">
      <div class="col-sm-12 col-md-6 col-lg-3 pd-b-20">
        <div class="row txt-center justify-content-md-center">
          <app-aloha-card
            image="{{ imageOne }}"
            rarity="{{ rarityOne }}"
            background="{{ backgroundOne }}"
          ></app-aloha-card>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3 pd-b-20">
        <div class="row txt-center justify-content-md-center">
          <app-aloha-card
            image="{{ imageTwo }}"
            rarity="{{ rarityTwo }}"
            background="{{ backgroundTwo }}"
          ></app-aloha-card>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3 pd-b-20">
        <div class="row txt-center justify-content-md-center">
          <app-aloha-card
            image="{{ imageThree }}"
            rarity="{{ rarityThree }}"
            background="{{ backgroundThree }}"
          ></app-aloha-card>
        </div>
      </div>
    </div>

    <div
      *ngIf="!loading && isMetamaskConnected"
      class="row p-b-30 justify-center"
    >
      <div class="col-lg-4 p-b-30">
        <div class="row center">
          <div class="col-lg-12">
            <img src="assets/img/buy-packs/nft-pack.png" class="deck" alt="" />
          </div>
          <div class="col-lg-12">
            <span class="title-pack">ALOHA NFT PACK:</span>
          </div>
          <div class="col-lg-12">
            <span class="season-txt">x2 Rare NFTs</span>
          </div>
          <div class="col-lg-12">
            <span class="season-txt">14% Ultra Rare NFT</span>
          </div>
          <div class="col-lg-12">
            <span class="season-txt">3% VIP NFT</span>
          </div>
          <div class="col-lg-12">
            <span class="season-txt">={{ packPriceToShow }}K Aloha</span>
          </div>
          <div class="col-lg-12">
            <button
              class="btn btn-buyEnvelope py-1 px-4 mb-2 mt-3"
              *ngIf="haspackAllowance && !packWaiting"
              (click)="buyPack()"
            >
              Buy Pack
            </button>
            <button
              class="btn btn-approve py-1 px-4 mb-2 mt-3"
              *ngIf="!haspackAllowance && !packWaiting"
              (click)="approveAloha()"
            >
              Approve Aloha's
            </button>
            <button
              class="btn btn-buyEnvelope py-1 px-4 mb-2 mt-3"
              *ngIf="packWaiting"
            >
              Wait...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>