<div
  *ngIf="imageType === 'static'"
  class="aloha-card box"
  [routerLink]="(network == 'ETH' ? (id ? ['/card/' + id + '/eth'] : []) : (id ? ['/card/' + id] : []))"
  [ngStyle]="{ cursor: id ? 'pointer' : '' }"
>
  <div class="ribbon ribbon-top-right" *ngIf="ribbon">
    <span>{{ ribbonContent }}</span>
  </div>
  <img class="image" [src]="imageSource" alt="img-mobile" />
  <img *ngIf="hasBorder" class="frame" [src]="frameSource" alt="img-mobile" />
  <img *ngIf="headSource" class="img-class" [src]="headSource" alt="">
</div>

<div
  *ngIf="imageType === 'animated'"
  class="aloha-card"
  [routerLink]="(network == 'ETH' ? (id ? ['/card/' + id + '/eth'] : []) : (id ? ['/card/' + id] : []))"
  [ngStyle]="{ cursor: id ? 'pointer' : '' }"
>
  <div class="ribbon ribbon-top-right" *ngIf="ribbon">
    <span>{{ ribbonContent }}</span>
  </div>
  <video autoplay loop class="image" alt="img-mobile">
    <source [src]="imageSource" type="video/ogg" />
  </video>
</div>
