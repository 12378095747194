<section>
  <div class="container">
    <div class="row justify-center">
      <div class="col-lg-8">
        <div class="title first-title">
          Aloha is a <span class="color-2">Wi-Fi sharing app</span> that
          connects the community to local businesses
        </div>
      </div>
    </div>

    <div class="row justify-center">
      <div class="col-lg-7">
        <img src="assets/img/liquidity/bakery.png" alt="" class="img-fluid" />
      </div>
      <div class="col-lg-9 p-title">
        <div class="title">
          We have also developed a bridge for app users to join the Aloha DEFI
          community
        </div>
      </div>
    </div>

    <div class="row justify-center">
      <div class="col-lg-7">
        <img src="assets/img/liquidity/trade.png" alt="" class="img-fluid" />
      </div>
      <div class="col-lg-9 p-title">
        <div class="title">
          Aloha DeFI offers users the chance to stake for NFT’s, benefit from
          generous apy’s in liquidity pools, take partin our DAO, all whilst 
          <span class="color-2">earning a passive income.</span>
        </div>
      </div>
    </div>

    <div class="row justify-center">
      <div class="col-lg-7">
        <img src="assets/img/liquidity/pool.png" alt="" class="img-fluid" />
      </div>

      <div class="col-lg-9 p-title">
        <div class="title-2">
          Provide liquidity in our
          <span class="color-2">Uniswap LP pool</span> now and be
          automaticallyentered into our 10000 Aloha Prize draw + provide 0.15 ETH
          alongwith ALOHA or more for 14 days and be automatically added tothe
          Whitelist for our forthcoming SHAKA token sale event.
        </div>
      </div>

      <div class="col-lg-12 p-t-20 justify-center">
        <a
          href="https://app.uniswap.org/#/add/v2/ETH/0x455F7Ef6D8BCfc35f9337e85aEe1B0600a59FabE"
          target="_blank"
        >
          <button class="btn btn-enter">CLICK TO ENTER</button>
        </a>
      </div>

      <div class="col-lg-8 p-title total-center">
        <div class="title-2 txt-icons">
          New to liquidity providing? Here’s how it’s done:
        </div>
        <a
          href="https://alohadefi.medium.com/instructions-for-the-aloha-liquidity-event-3f7bc1655a4"
          target="_blank"
        >
          <img src="assets/img/liquidity/info.png" alt="" class="img-fluid" />
        </a>
      </div>

      <div class="col-lg-8 p-icons total-center">
        <div class="title-2 txt-icons">
          For assistance, ask the AholaDefi team on Telegram:
        </div>
        <a href="https://t.me/AlohaDeFi" target="_blank">
          <img
            src="assets/img/liquidity/telegram.png"
            alt=""
            class="img-fluid"
          />
        </a>
      </div>

      <div class="col-lg-9 p-title">
        <div class="title-2">
          <a href="https://www.alohadefi.io" target="_blank">
            www.alohadefi.io
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="slider-partners">
          <a
            href="https://www.platinumcryptoacademy.com/"
            target="_blank"
            rel="nofollow"
          >
            <img src="assets/img/landing/partners/cryptonaire.png" alt="" />
          </a>

          <a href="https://www.flowdesk.co/" target="_blank" rel="nofollow">
            <img src="assets/img/landing/partners/flowdesk.png" alt="" />
          </a>
          <a href="https://www.dextools.io/app/" target="_blank" rel="nofollow">
            <img
              src="assets/img/landing/partners/promoted_by_dext.png"
              alt=""
            />
          </a>
          <a
            href="https://www.autonio.foundation/"
            target="_blank"
            rel="nofollow"
          >
            <img
              src="assets/img/landing/partners/autonio-foundation.png"
              alt=""
            />
          </a>
          <a href="https://arkerlabs.com/" target="_blank" rel="nofollow">
            <img src="assets/img/landing/partners/arkerlabs.png" alt="" />
          </a>
          <a href="https://ctdsec.com/" target="_blank" rel="nofollow">
            <img src="assets/img/landing/partners/ctd-sec.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
