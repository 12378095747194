import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlohaService } from 'src/app/services/aloha.service';
import { WalletService } from 'src/app/services/wallet.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pool',
  templateUrl: './pool.component.html',
  styleUrls: ['./pool.component.scss'],
})
export class PoolComponent implements OnInit, OnDestroy {
  account;
  loading: boolean = false;
  firstLoading: boolean = false;
  blockchainLoading: boolean = false;
  rarity;
  backgrounds;
  totalImages;
  totalImagesWithLimits;
  pools;
  checkConnection;
  allowedAloha;
  allowedToken = {};
  inStake = {};

  constructor(
    private route: ActivatedRoute,
    private readonly walletService: WalletService,
    private readonly aloha: AlohaService
  ) {}

  async ngOnInit(): Promise<void> {
    this.firstLoading = true;
    await this.aloha.configNetwork();
    this.account = await this.walletService.getAccount();
    this.rarity = this.route.snapshot.paramMap.get('rarity');
    this.loading = true;
    this.backgrounds = await this.aloha.getTotalBackgrounds();
    this.totalImages = await this.aloha.getImages(this.rarity);
    this.totalImagesWithLimits = await this.aloha.getImagesWithLimits(
      this.rarity
    );
    this.pools = await this.aloha.getPools(this.rarity);
    this.firstLoading = false;

    this.checkConnection = setInterval(async () => {
      if (await this.walletService.getAccount()) {
        this.loading = true;
        await this.refreshAllowance();
        await this.refreshInStake();
        this.loading = false;
        clearInterval(this.checkConnection);
      }
    }, 1000);
  }

  async ngOnDestroy(): Promise<void> {
    clearInterval(this.checkConnection);
  }

  async simpleStake(rarity: number): Promise<void> {
    this.blockchainLoading = true;
    this.loading = true;
    this.aloha.simpleStake(rarity).finally(async () => {
      await this.refreshInStake();
      this.loading = false;
      this.blockchainLoading = false;
    });
  }

  async pairStake(address: string, rarity: number): Promise<void> {
    this.blockchainLoading = true;
    this.loading = true;
    this.aloha.pairStake(address, rarity).finally(async () => {
      await this.refreshInStake();
      this.loading = false;
      this.blockchainLoading = false;
    });
  }

  async approveAloha(): Promise<void> {
    if (this.aloha.connectedToRootChain) {
      return await this.approveToken(environment.rootChainStaking);
    }
    return await this.approveToken(environment.childChainStaking);
  }

  async approveToken(address: string): Promise<void> {
    await this.aloha.approve(address);
    this.blockchainLoading = true;
    this.loading = true;
    this.refreshAllowance().finally(async () => {
      await this.refreshInStake();
      this.loading = false;
      this.blockchainLoading = false;
    });
  }

  async refreshAllowance(): Promise<void> {
    for (const pool of this.pools) {
      this.allowedAloha = await this.aloha.allowedAloha(pool.alohaAmount);
      this.allowedToken[pool.address] = await this.aloha.allowedToken(
        pool.address,
        pool.erc20Amount
      );
    }
  }

  async refreshInStake(): Promise<void> {
    for (const pool of this.pools) {
      this.inStake[pool.address] = await this.aloha.inStake(
        pool.address,
        this.rarity
      );
    }
  }

  toTime(seconds): string {
    const minutes = Math.floor(Number(seconds) / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return days + ' day(s)';
    }
    if (hours > 0) {
      return hours + ' hour(s)';
    }
    if (minutes > 0) {
      return minutes + ' minute(s)';
    }
  }
}
