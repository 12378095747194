import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuyAlohaEnvelopesComponent } from './components/buy-aloha-envelopes/buy-aloha-envelopes.component';
import { BuyAlohaNewPackComponent } from './components/buy-aloha-new-pack/buy-aloha-new-pack.component';
import { BuyPacksComponent } from './components/buy-packs/buy-packs.component';
import { CardComponent } from './components/card/card.component';
import { CollectionComponent } from './components/collection/collection.component';
import { ExploreComponent } from './components/explore/explore.component';
import { LandingComponent } from './components/landing/landing.component';
import { LiquidityEventComponent } from './components/liquidity-event/liquidity-event.component';
import { LotteryComponent } from './components/lottery/lottery.component';
import { MediaComponent } from './components/media/media.component';
import { NftComponent } from './components/nft/nft.component';
import { PoolComponent } from './components/pool/pool.component';
import { StakeComponent } from './components/stake/stake.component';
import { SurfRidersComponent } from './components/surf-riders/surf-riders.component';
import { WhatsHotComponent } from './components/whats-hot/whats-hot.component';

const routes: Routes = [
  {
    path: 'explore',
    component: ExploreComponent,
    data: {
      title: 'Aloha - Most recent minted NFTs',
      path: 'https://alohadefi.io/explore',
      description: 'Explore the latest minted Aloha NFTs.',
    },
  },
  {
    path: 'media',
    component: MediaComponent,
    data: {
      title: 'Aloha - Media Page',
      path: 'https://alohadefi.io/media',
      description:
        'The Aloha DeFi media page, with all the publications spreading the word about the brand and the NFTs.',
    },
  },
  {
    path: 'pool/:rarity',
    component: PoolComponent,
    data: {
      title: 'Aloha DeFi - Your gateway to the Aloha DeFi world',
      path: 'https://alohadefi.io/pool/1',
      description: 'Aloha NFT pool.',
    },
  },
  {
    path: 'card/:id/:network',
    component: CardComponent,
    data: {
      title: 'Aloha DeFi - Your gateway to the Aloha DeFi world',
      path: 'https://alohadefi.io/card/1/matic',
      description: 'Aloha Matic.',
    },
  },
  {
    path: 'card/:id',
    component: CardComponent,
    data: {
      title: 'Aloha DeFi - Your gateway to the Aloha DeFi world',
      path: 'https://alohadefi.io/card/1',
      description: 'Aloha NFTs.',
    },
  },
  {
    path: '',
    component: LandingComponent,
    data: {
      title: 'Aloha DeFi - Your gateway to the Aloha DeFi world',
      path: 'https://alohadefi.io/',
      description:
        'Aloha DeFi is your gateway to the DeFi world. With NFTs, staking, and a DAO platform that utilizes NFTs. Earn platform rewards by participating in the DAO.',
    },
  },
  {
    path: 'nft',
    component: NftComponent,
    data: {
      title: 'Aloha NFTs Collections',
      path: 'https://alohadefi.io/nft',
      description:
        'Explore the latest seasons of the Aloha NFTs. Browse through the previous seasons and pick your favourite NFTs.',
    },
  },
  {
    path: 'stake',
    component: StakeComponent,
    data: {
      title: 'Stake Aloha to Earn NFTs',
      path: 'https://alohadefi.io/stake',
      description:
        'Stake to win an exclusive Aloha NFT: rare, ultra rare and VIP.',
    },
  },
  {
    path: 'collection',
    component: CollectionComponent,
    data: {
      title: 'Aloha - Browse the Aloha NFT Collections',
      path: 'https://alohadefi.io/collection',
      description: 'Your Aloha NFT collection.',
    },
  },
  {
    path: 'liquidity-event',
    component: LiquidityEventComponent,
    data: {
      title: 'Aloha - Liquidity Event',
      path: 'https://alohadefi.io/liquidity-event',
      description:
        'Provide liquidity in our Uniswap LP pool now and be automatically entered into our 10000 Aloha Prize draw.',
    },
  },
  {
    path: 'buyAlohaEnvelopes',
    component: BuyAlohaEnvelopesComponent,
    data: {
      title: 'Aloha DeFi - Your gateway to the Aloha DeFi world',
      path: 'https://alohadefi.io/buyAlohaEnvelopes',
      description: 'N/A',
    },
  },
  {
    path: 'buyAlohaNewPack',
    component: BuyAlohaNewPackComponent,
    data: {
      title: 'Aloha DeFi - Your gateway to the Aloha DeFi world',
      path: 'https://alohadefi.io/buyAlohaEnvelopes',
      description: 'N/A',
    },
  },
  {
    path: 'buy-packs',
    component: BuyPacksComponent,
    data: {
      title: 'Buy Aloha NFT Packs',
      path: 'https://alohadefi.io/buy-packs',
      description:
        'Buy Aloha NFT packs and use them to play the Surf Invaders NFT based game.',
    },
  },
  {
    path: 'surf',
    component: SurfRidersComponent,
    data: {
      title: 'Aloha - Play Surf Invaders',
      path: 'https://alohadefi.io/surf',
      description: "Play2Earn - Aloha's new NFTs based game Surf Invaders.",
    },
  },
  {
    path: 'whats-hot',
    component: WhatsHotComponent,
    data: {
      title: 'Aloha - Latest News',
      path: 'https://alohadefi.io/whats-hot',
      description: 'Read the latest news and developments at Aloha DeFi.',
    },
  },
  {
    path: 'raffle',
    component: LotteryComponent,
    data: {
      title: 'Aloha - Join the Aloha Raffle',
      path: 'https://alohadefi.io/raffle',
      description:
        'Join to Aloha DeFi raffle and earn rewards by holding Aloha tokens.',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
