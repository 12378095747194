<section class="hot">
  <!--   <div class="container retrospective-block">
    <span class="retrospective-text"><span class="title-pink">EVERYONE </span><span class="title-white">WINS AN NFT!</span></span>
    <h2 class="title-white small">...in the <span class="title-pink small"><img style="height: 75px;" src="../../../assets/img/raffle/aloha-text.png"> Celebration Raffle</span> hosted on <img style="height: 75px;" src="../../../assets/img/raffle/polygon.png"> <b>polygon</b></h2>
    <h3 class="title-white retrospective-subtext">Stake Aloha and you'll win a super rare Onyx NFT, celebrating 9 seasons of our NFTs!</h3>
    <h2 class="title-white big">Which Aloha Character will <span class="title-pink">YOU </span>choose?</h2>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-2">
        <img class="stack" src="../../../assets/img/cards/stacks/nft2_bambi.png">
      </div>
      <div class="col-md-2">
        <img class="stack" src="../../../assets/img/cards/stacks/nft2_bodhi.png">
      </div>
      <div class="col-md-2">
        <img class="stack" src="../../../assets/img/cards/stacks/nft2_daisy.png">
      </div>
      <div class="col-md-2">
        <img class="stack" src="../../../assets/img/cards/stacks/nft2_duke.png">
      </div>
      <div class="col-md-2">
        <img class="stack" src="../../../assets/img/cards/stacks/nft2_jazz.png">
      </div>
      <div class="col-md-2">
        <img class="stack" src="../../../assets/img/cards/stacks/nft2_jin.png">
      </div>
    </div>
    <h3><span class="title-pink">Step 1: </span><span class="title-white">Stake a Minimum of 5000 Aloha / </span><span class="title-pink small">Step 2: </span><span class="title-white">Claim your NFT after 14 days / </span><span class="title-pink">Step 3: </span><span class="title-white">Repeat!</span></h3>
  </div> -->

  <!--  <div class="container">
   
    <div class="row p-t-60 justify-center">
      <div class="col-lg-10">
        <img
          src="assets/img/raffle/tittle-raffle.png"
          alt=""
          class="img-fluid"
        />
      </div>

      <div class="col-lg-10">
        <img src="assets/img/raffle/party.png" alt="" class="img-fluid" />
      </div>
    </div>

    <div class="row justify-center">
      <div class="col-lg-4 text-center pd-t-20">
        <img
          src="assets/img/raffle/step-1.png"
          alt=""
          class="img-fluid pd-t-20"
        />
      </div>
      <div class="col-lg-4 text-center pd-t-20">
        <img
          src="assets/img/raffle/step-2.png"
          alt=""
          class="img-fluid pd-t-20"
        />
      </div>
    </div>

    <div class="row p-t-60 justify-center">
      <div class="col-lg-10">
        <img src="assets/img/raffle/separator.png" alt="" class="img-fluid" />
      </div>

      <div class="col-lg-10 pd-t-20">
        <img src="assets/img/raffle/types.png" alt="" class="img-fluid" />
      </div>
    </div>

    <div class="row p-t-20 justify-center">
      <div class="col-lg-10 text-center">
        <div class="row p-custom-3">
          <div class="col-lg-12">
            <p class="txt-box">
              Tickets will be calculated depending on the volume tokens you
              hold. Every 5000 Aloha = 1 raffle ticket
            </p>
            <p class="txt-box">
              Rules: If ALOHA tokens are transfered or sold lottery tickets are null and
              void
            </p>
            <p class="txt-box">
              1 win per address – Having multiple tickets increase your chances
              of winning.
            </p>
            <p class="txt-box">
              <span class="pink"> The rafﬂe is now LIVE</span> and will end on
              December 16th. Winners will be revealed on December 17th 2021.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row p-t-40 justify-center" *ngIf="loading">
      <div class="col-lg-12 text-center">
        <span class="prize-txt">Loading Raffle...</span>
      </div>
    </div>

    <div class="row pd-t-40 justify-center" *ngIf="!address && !loading">
      <div class="col-lg-4 text-center">
        <button
          data-toggle="modal"
          data-target="#walletModal"
          class="btn btn-primary py-1 px-4 mb-2"
        >
          Connect Wallet
        </button>
      </div>
    </div>

    <div class="row justify-center" *ngIf="address && ableToBuy && !loading">
      <div class="col-lg-12 pd-t-40 text-center" *ngIf="ableToBuy == 'YES'">
        <div class="row justify-center">
          <div class="col-lg-12">
            <div class="pd-t-20 text-center">
              <span class="prize-tickets">
                You will receive
                {{ availableTickets | number: "1.0-0" }} tickets for this raffle
              </span>
            </div>
          </div>
          <div class="col-lg-4 pd-t-40">
            <button
              (click)="buyTickets()"
              class="btn btn-primary py-1 px-4 mb-2"
            >
              Get Tickets!
            </button>
          </div>
        </div>
      </div>
      <div
        class="col-lg-4 pd-t-40 text-center"
        *ngIf="
          (ableToBuy == 'FINISHED' && raffleClaimed) || ableToBuy == 'NONE'
        "
      >
        <button disabled class="btn btn-primary py-1 px-4 mb-2">
          There are no active raffles
        </button>
      </div>
      <div
        class="col-lg-12 pd-t-40 text-center"
        *ngIf="ableToBuy == 'ALREADY_IN' && !raffleClaimed"
      >
        <div class="row justify-center">
          <div class="col-lg-12">
            <div class="pd-t-20 text-center">
              <span class="prize-tickets">
                You have {{ myTickets | number: "1.0-0" }} tickets for this
                raffle
              </span>
            </div>
          </div>
          <div class="col-lg-4 pd-t-40">
            <button disabled class="btn btn-primary py-1 px-4 mb-2">
              Already participated
            </button>
          </div>
        </div>
      </div>
      <div
        class="col-lg-12 pd-t-40 text-center"
        *ngIf="ableToBuy == 'NOT_ENOUGHT'"
      >
        <div class="row justify-center">
          <div class="col-lg-12">
            <div class="text-center">
              <span class="prize-tickets">
                You need atleast {{ tokensRequired }} {{ tokenSymbol }} to
                participate
              </span>
            </div>
          </div>
          <div class="col-lg-4 pd-t-40">
            <button disabled class="btn btn-primary py-1 px-4 mb-2">
              Insufficient funds
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row p-t-60 justify-center"
      *ngIf="address && !raffleClaimed && raffleActive && !loading"
    >
      <div class="col-lg-4 text-center">
        <button
          (click)="claimRaffleReward()"
          class="btn btn-primary py-1 px-4 mb-2"
        >
          Draw Raffle!
        </button>
      </div>
    </div>
  </div> -->

  <div class="container retrospective-block p-b-40 p-t-60">
    <span class="retrospective-text">
      <span class="title-pink">NEXT LOTTERY </span>
      <span class="title-white"></span>
    </span>
    <h2 class="title-white big">Stay tuned!</h2>
  </div>
</section>
