import { Component, Input, OnInit } from '@angular/core';
import { AlohaService } from 'src/app/services/aloha.service';

@Component({
  selector: 'app-aloha-card',
  templateUrl: './aloha-card.component.html',
  styleUrls: ['./aloha-card.component.scss'],
})
export class AlohaCardComponent implements OnInit {
  @Input() id: number;
  @Input() image: number;
  @Input() rarity: number;
  @Input() background: number;
  @Input() head: string;
  @Input() ribbon = false;
  @Input() ribbonContent: string;
  @Input() network: string;

  imageSource = '';
  frameSource = '';
  headSource = '';
  imageType = 'static';
  hasBorder = true;

  animatedCards = [13, 14, 21, 22, 30, 56, 57, 58, 59, 60, 61];

  cardsWithoutBorders = [23, 31, 44, 45, 46, 47, 48, 49, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73];

  constructor(
    private aloha: AlohaService,
  ) {}

  ngOnInit(): void {
    if (this.rarity < 1 || this.rarity > 3) {
      throw new Error('Invalid rarity: ' + this.rarity);
    }

    if (this.animatedCards.indexOf(parseInt(this.image + '', undefined)) !== -1) {
      this.imageType = 'animated';
      this.imageSource = '/assets/img/cards/images/' + this.image + '.ogg';
      return;
    }

    this.hasBorder = this.cardsWithoutBorders.indexOf(parseInt(this.image + '', undefined)) === -1;

    if(this.head) {
      this.headSource = '/assets/img/v2/' + this.head + '.png';

    }

    this.imageSource = '/assets/img/cards/images/' + this.image + '.png';
    this.frameSource =
      '/assets/img/cards/frames/' +
      this.rarity +
      '_' +
      this.background +
      '_' +
      this.aloha.getSerieByImage(this.image + '') +
      '.png';

    // this.frameSource = '/assets/img/cards/frames/1_2_2.png';
  }
}