import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AlohaService } from 'src/app/services/aloha.service';

@Component({
  selector: 'app-surf-riders',
  templateUrl: './surf-riders.component.html',
  styleUrls: ['./surf-riders.component.scss'],
})
export class SurfRidersComponent implements OnInit {
  constructor(public readonly aloha: AlohaService, private http: HttpClient) {}

  loading = false;
  network;
  isPolygon = true;
  myNFTs = [];
  leaderBoard: any[] | null = null;

  async ngOnInit() {
    await this.loadData();
  }

  async checkNetwork() {
    this.isPolygon = await this.aloha.isConnectedToMatic();
  }

  async loadData() {
    this.checkNetwork();
    this.loadLeaderboard();
    this.myNFTs = (await this.aloha.alohaNFTs()) as any;
    this.network = this.aloha.currentNetwork;
  }

  async loadLeaderboard(): Promise<void> {
    this.leaderBoard = null;

    const response = (await this.http
      .get('https://sandbox.arkerlabs.com:4016/game/winners')
      .toPromise()) as any;

    const final = [];

    for (const res of response) {
      if (res != null) {
        final.push(res);
      }
    }

    this.leaderBoard = final;
  }
}
