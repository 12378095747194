import { Component, OnInit } from '@angular/core';
import { AlohaService } from 'src/app/services/aloha.service';
import { MathService } from 'src/app/services/math.service';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-lottery',
  templateUrl: './lottery.component.html',
  styleUrls: ['./lottery.component.scss'],
})
export class LotteryComponent implements OnInit {
  constructor(
    public readonly aloha: AlohaService,
    public readonly wallet: WalletService,
    public readonly math: MathService
  ) {}

  loading = false;
  isPolygon = true;
  address: string = null;

  raffleClaimed: boolean = false;
  raffleActive: boolean = false;
  tokensRequired: number;
  tokenBalance: number;
  availableTickets: number;
  myTickets: number;
  tokenSymbol: string;
  ableToBuy: 'NONE' | 'ALREADY_IN' | 'NOT_ENOUGHT' | 'FINISHED' | 'YES' = null;

  async ngOnInit() {
    await this.loadData();
  }

  async loadData() {
    this.loading = true;

    this.isPolygon = await this.aloha.isConnectedToMatic();
    this.address = await this.wallet.getAccount();

    if (!this.address) {
      this.loading = false;
      return;
    }

    const raffleInfo = await this.aloha.getRaffleInfo();

    if (raffleInfo['startingTokenID'] == 0) {
      this.loading = false;
      this.ableToBuy = 'NONE';
      return;
    }

    const boughtTickets = await this.aloha.getBoughtTickets();
    const tokenBalance = await this.aloha.getTokenBalance();
    const tokenDecimals = await this.aloha.getTokenDecimals();

    this.tokenSymbol = await this.aloha.getTokenSymbol();

    this.tokensRequired = this.math.toHumanValue(
      raffleInfo['minimumTokensBalance'],
      tokenDecimals
    );

    this.raffleActive =
      new Date().getTime() / 1000 > raffleInfo['endTimestamp'];

    this.tokenBalance = this.math.toHumanValue(tokenBalance, tokenDecimals);

    if (
      boughtTickets > 0 &&
      new Date().getTime() / 1000 < raffleInfo['endTimestamp']
    ) {
      this.myTickets = boughtTickets;
      this.ableToBuy = 'ALREADY_IN';
    } else if (this.tokensRequired > this.tokenBalance) {
      this.ableToBuy = 'NOT_ENOUGHT';
    } else if (new Date().getTime() / 1000 > raffleInfo['endTimestamp']) {
      this.ableToBuy = 'FINISHED';
    } else {
      this.availableTickets =
        this.tokenBalance /
        this.math.toHumanValue(raffleInfo['ticketPrice'], tokenDecimals);

      this.ableToBuy = 'YES';
    }

    if (new Date().getTime() / 1000 > raffleInfo['endTimestamp']) {
      this.raffleClaimed = await this.aloha.isRaffleClaimed();
    }

    this.loading = false;
  }

  async buyTickets() {
    this.loading = true;
    await this.aloha.buyRaffleTickets();
    await this.loadData();
    this.loading = false;
  }

  async claimRaffleReward() {
    this.loading = true;
    await this.aloha.claimRaffleReward();
    await this.loadData();
    this.loading = false;
  }
}
