import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AlohaService } from 'src/app/services/aloha.service';
import { WalletService } from 'src/app/services/wallet.service';
import { MaticPOSClient } from '@maticnetwork/maticjs';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class CollectionComponent implements OnInit, OnDestroy {
  @ViewChild('claimModal') claimModal: ElementRef;

  myNFTs;
  account;
  myNFTsOnMirrorNetwork;
  removeClaimAddress;
  removeClaimRarity;
  removeClaimV1;
  myPendingV1NFTs;
  myPendingNFTs;
  myPendingNFTsOnMirrorNetwork;
  pendingERC721Txs;
  checkConnection;
  maticPOSClient;
  loading = false;
  native;
  network;
  pendingFromMaticTransfer;

  constructor(
    private readonly walletService: WalletService,
    public readonly aloha: AlohaService
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.aloha.configNetwork();

    this.network = this.aloha.currentNetwork;

    this.checkConnection = setInterval(async () => {
      if (await this.walletService.getAccount()) {
        this.loadUserData();
        clearInterval(this.checkConnection);
      }
    }, 1000);

    if (await this.walletService.getAccount()) {
      clearInterval(this.checkConnection);
      await this.loadUserData();
    }

    this.maticPOSClient = new MaticPOSClient({
      network: 'mainnet', // 'testnet'
      version: 'v1', // 'mumbai'
      parentProvider: this.aloha.connectedToRootChain
        ? this.aloha.provider
        : this.aloha.rootProvider,
      maticProvider: this.aloha.connectedToRootChain
        ? this.aloha.childProvider
        : this.aloha.provider,
    });

    this.loading = false;
  }

  private async loadUserData(): Promise<void> {
    this.loading = true;
    this.account = await this.walletService.getAccount();
    this.myNFTs = await this.aloha.alohaNFTs();
    console.log(this.myNFTs);
    this.myPendingNFTs = await this.aloha.pendingAlohaNFTs();
    this.myPendingV1NFTs = await this.aloha.pendingAlohaV1NFTs();
    this.myPendingNFTsOnMirrorNetwork = await this.aloha.pendingAlohaNFTsOnMirrorNetwork();
    this.pendingFromMaticTransfer = await this.getPendingFromMaticTransfer();
    this.loading = false;
  }

  async claim(address: string, rarity: number, v1 = false): Promise<void> {
    this.loading = true;

    if (v1) {
      this.aloha.withdrawV1(address, rarity).finally(() => {
        this.loading = false;
        this.ngOnInit();
      });
    } else {
      this.aloha.withdraw(address, rarity).finally(() => {
        this.loading = false;
        this.ngOnInit();
      });
    }
  }

  setRemoveClaim(address: string, rarity: number, v1 = false): void {
    this.removeClaimAddress = address;
    this.removeClaimRarity = rarity;
    this.removeClaimV1 = v1;
  }

  async forceClaim(address: string, rarity: number, v1 = false): Promise<void> {
    this.loading = true;
    this.claimModal.nativeElement.click();

    if (v1) {
      this.aloha.forceWithdrawV1(address, rarity).finally(() => {
        this.loading = false;
        this.ngOnInit();
      });
    } else {
      this.aloha.forceWithdraw(address, rarity).finally(() => {
        this.loading = false;
        this.ngOnInit();
      });
    }
  }

  async ngOnDestroy(): Promise<void> {
    clearInterval(this.checkConnection);
  }

  hoursTo(timestamp): string {
    const date1 = new Date();
    const date2 = new Date(timestamp * 1000);

    if (date1 > date2) {
      return 'CLAIM';
    }

    const diffInSeconds = Math.abs(date1.getTime() - date2.getTime()) / 1000;
    const minutes = Math.ceil(diffInSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return days + ' days left';
    }
    if (hours > 0) {
      return hours + ' hours left';
    }

    return minutes + ' minutes left';
  }

  async finishTransferERC721(txHash: string): Promise<void> {
    this.loading = true;

    this.maticPOSClient.web3Client.setParentDefaultOptions({
      from: this.account,
    });

    try {
      const payload = await this.maticPOSClient.posRootChainManager.processReceivedMessage(
        this.aloha.rootTunnelData,
        txHash
      );
      this.removeFromMaticPendingTxHash(txHash);
      location.reload();
    } catch (error) {
      if (
        error.message === 'Burn transaction has not been checkpointed as yet'
      ) {
        alert('This transfer claim needs about ~30 min to be approved');
      } else if (error.message.includes('EXIT_ALREADY_PROCESSED')) {
        alert('Already claimed!');
        this.removeFromMaticPendingTxHash(txHash);
        location.reload();
      }
    }

    this.loading = false;
  }

  getPendingFromMaticTransfer(): any[] {
    const localData: string = localStorage.getItem('txFromMaticHashesNFTs');
    const txHashesNFTs =
      JSON.parse(localData) == null ? [] : JSON.parse(localData);

    return txHashesNFTs;
  }

  removeFromMaticPendingTxHash(hash: string): void {
    const localData: string = localStorage.getItem('txFromMaticHashesNFTs');
    const txHashes = JSON.parse(localData) == null ? [] : JSON.parse(localData);
    const newTxHashes = [];

    for (const txHash of txHashes) {
      if (txHash.hash !== hash) {
        newTxHashes.push({ hash: txHash.hash, address: txHash.address });
      }
    }

    localStorage.setItem('txFromMaticHashesNFTs', JSON.stringify(newTxHashes));
    this.pendingFromMaticTransfer = this.getPendingFromMaticTransfer();
  }
}
