import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlohaService } from './services/aloha.service';
import { WalletService } from './services/wallet.service';
import { UpdateDynamicInfo } from './services/update-dynamic-info.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('walletModal') walletModal: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private readonly walletService: WalletService,
    public readonly alohaService: AlohaService,
    private readonly metatagService: UpdateDynamicInfo,
    private readonly router: Router,
    private gtmService: GoogleTagManagerService,
  ) {}

  address: string = null;
  network: string = null;
  hasMetamask: boolean;

  showLayout = false;

  async ngOnInit(): Promise<void> {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.showLayout = val.url !== '/';
        if (val.url === '/buyAlohaNewPack') {
          console.log('SEND EVENT');
          this.gtmService.pushTag({
            event: 'conversion',
            pageName: val.url,
            send_to: "AW-961083744/2ZqFCODA76kDEODyo8oD",

          });
          this.gtmService.pushTag({
            event: 'Purchase',
            pageName: val.url
          });
        } else if (val.url === '/stake') {
          this.gtmService.pushTag({
            event: 'Stake',
            pageName: val.url
          });
        }
      }
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(data =>
      this.metatagService.updateInfo(data['title'], data['path'], data['description'])
    );

    this.walletService.init();
    this.hasMetamask = this.walletService.isMetamaskInstalled();

    this.loadData();
  }

  async loadData() {
    await this.alohaService.configNetwork();
    this.walletService.getAccount().then((account) => {
      this.address = account;
    });

    this.network = this.alohaService.currentNetwork;
  }

  async connectWithMetamask(): Promise<void> {
    this.address = await this.walletService.connectWithMetamask();
    this.walletModal.nativeElement.click();
    this.loadData();
  }

  async connectWithWalletConnect(): Promise<void> {
    this.address = await this.walletService.connectWithWalletConnect();
    this.walletModal.nativeElement.click();
    this.loadData();
  }

  async switchToMatic(): Promise<void> {
    window['ethereum'].request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x89',
          chainName: 'Matic',
          nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
          },
          rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
        },
      ],
    });
  }

  comingSoon() {
    alert('Coming Soon!');
  }
}
