import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AlohaService } from 'src/app/services/aloha.service';
import { MathService } from 'src/app/services/math.service';
import { WalletService } from 'src/app/services/wallet.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-buy-aloha-envelopes',
  templateUrl: './buy-aloha-envelopes.component.html',
  styleUrls: ['./buy-aloha-envelopes.component.scss']
})
export class BuyAlohaEnvelopesComponent implements OnInit {

  account;
  loading: boolean = true;
  isMetamaskConnected: boolean = false;
  pack3Waiting: boolean = false;
  pack4Waiting: boolean = false;
  pack5Waiting: boolean = false;
  hasPack3Allowance: boolean = false;
  hasPack4Allowance: boolean = false;
  hasPack5Allowance: boolean = false;
  pack3Price;
  pack4Price;
  pack5Price;
  pack3PriceToShow;
  pack4PriceToShow;
  pack5PriceToShow;
  opened = false;
  isPolygon = true;

  // Pack Results
  imageOne;
  rarityOne;
  backgroundOne;
  imageTwo;
  rarityTwo;
  backgroundTwo;
  imageThree;
  rarityThree;
  backgroundThree;

  constructor(
    private aloha: AlohaService,
    private wallet: WalletService,
    private cdr: ChangeDetectorRef,
    private math: MathService,
  ) {}

  ngOnInit(): void {
    const observable = this.aloha.createObservable();
    const errorObservable = this.aloha.createErrorObservable();

    errorObservable.pipe(take(1)).subscribe(value => {
      if (value) {
        this.pack3Waiting = false;
        this.pack4Waiting = false;
        this.pack5Waiting = false;
        this.cdr.detectChanges();
      }
    });

    observable.pipe(take(1)).subscribe(value => {
      if (value) {
        this.pack3Waiting = false;
        this.pack4Waiting = false;
        this.pack5Waiting = false;
        this.cdr.detectChanges();
      }
    });

    this.loadData();
  }

  async getPrices(){
    let decimals = await this.aloha.getAlohaDecimals();
    this.pack3Price = await this.aloha.getPackPrice(0);
    this.pack3PriceToShow = (this.math.toHumanValue(this.pack3Price, decimals)) / 1000;
    this.pack4Price = await this.aloha.getPackPrice(1);
    this.pack4PriceToShow = (this.math.toHumanValue(this.pack4Price, decimals)) / 1000;
    this.pack5Price = await this.aloha.getPackPrice(2);
    this.pack5PriceToShow = (this.math.toHumanValue(this.pack5Price, decimals)) / 1000;
  }

  async checkNetwork() {
    this.isPolygon = await this.aloha.isConnectedToMatic();
  }

  async loadData(){
    this.checkNetwork();
    this.account = await this.wallet.getAccount();
    if(this.account != null){
      this.isMetamaskConnected = true;
    }

    if(this.isMetamaskConnected){
      await this.aloha.configNetwork();
      await this.checkAllowances();
      await this.getPrices();
      this.loading = false;
    }
  }

  async checkAllowances(){
    await this.checkPack3Allowance();
    await this.checkPack4Allowance();
    await this.checkPack5Allowance();
  }

  private async checkPack3Allowance(){
    const packPrice = await this.aloha.getPackPrice(0);
    this.hasPack3Allowance = await this.aloha.allowedAlohaForBoosters(packPrice);
  }

  private async checkPack4Allowance(){
    const packPrice = await this.aloha.getPackPrice(1);
    this.hasPack4Allowance = await this.aloha.allowedAlohaForBoosters(packPrice);
  }

  private async checkPack5Allowance(){
    const packPrice = await this.aloha.getPackPrice(2);
    this.hasPack5Allowance = await this.aloha.allowedAlohaForBoosters(packPrice);

    this.pack3Waiting = false;
    this.pack4Waiting = false;
    this.pack5Waiting = false;
  }

  async approveAloha(num: number){
    
    if(num == 0){
      this.pack3Waiting = true;
    }
    if(num == 1){
      this.pack4Waiting = true;
    }
    if(num == 2){
      this.pack5Waiting = true;
    }

    await this.aloha.approve(this.aloha.currentNetworkBuyAlohaEnvelopes);
    await this.checkAllowances();
  }

  async buyPack(index: number){
    this.opened = false;
    if(index == 0){
      this.pack3Waiting = true;
    } else if(index == 1){
      this.pack4Waiting = true;
    } else {
      this.pack5Waiting = true;
    }

    await this.aloha.buyPack(index);
    this.showLastPack();
  }

  async showLastPack() {
    const pack = await this.aloha.getLastPack();
    const first = await this.aloha.getNftById(pack.first, 'MATIC');
    this.imageOne = first.image;
    this.backgroundOne = first.background;
    this.rarityOne = first.rarity;
    const second = await this.aloha.getNftById(pack.second, 'MATIC');
    this.imageTwo = second.image;
    this.backgroundTwo = second.background;
    this.rarityTwo = second.rarity;
    const third = await this.aloha.getNftById(pack.third, 'MATIC');
    this.imageThree = third.image;
    this.backgroundThree = third.background;
    this.rarityThree = third.rarity;
    this.opened = true;
  }
}
