<div class="slim-mainpanel">
  <div class="container">
    <!--  <div class="row row-xs justify-content-md-center">
      <div class="col-lg-12 pd-lg-t-40">
        <div class="row custom-padding justify-content-center">
          <div class="col-lg-12 farm-title pd-b-20">
            <img
              class="hg-50"
              src="assets/img/cards/rank/vip.png"
              alt="img-mobile"
            />
            <img
              class="pd-l-20 hg-50"
              src="assets/img/cards/rank/ultra-rare.png"
              alt="img-mobile"
            />
            <img
              class="pd-l-20 hg-50"
              src="assets/img/cards/rank/rare.png"
              alt="img-mobile"
            />
          </div>
          <div class="col-lg-12 farm-title title-vip txt-line-vip">
            <span>MY NFTs</span>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div *ngIf="loading" class="row justify-content-md-center pd-t-20">
      <div class="col-md-12 col-xl-12">
        <div class="d-flex ht-300 pos-relative align-items-center">
          <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
          </div>
        </div>
      </div>
    </div> -->

    <!--  <div *ngIf="!loading" class="row justify-content-md-center pd-t-20">
      <div
        class="col-sm-12 col-md-6 col-lg-3 pd-b-20"
        *ngFor="let myNFT of myNFTs"
      >
        <div class="row txt-center justify-content-md-center">
          <div class="col-md-12">
            <app-aloha-card
              id="{{ myNFT.id }}"
              network="{{ network == 'Ethereum' ? 'ETH' : 'MATIC' }}"
              image="{{ myNFT.image }}"
              rarity="{{ myNFT.rarity }}"
              background="{{ myNFT.background }}"
            ></app-aloha-card>
          </div>
        </div>
      </div>

      <div
        *ngIf="myNFTs.length == 0"
        class="col-sm-12 col-md-6 col-lg-3 pd-b-20"
      >
        <div class="row txt-center justify-content-md-center">
          <div class="col-md-12" [routerLink]="['/']">
            <img
              class="aloha-card pointer"
              src="assets/img/cards/add.png"
              alt="img-mobile"
            />
          </div>
        </div>
      </div>
    </div> -->

    <div class="row row-xs justify-content-md-center">
      <div class="col-lg-12 pd-lg-t-20">
        <div class="row custom-padding justify-content-center">
          <div class="col-lg-12 farm-title pd-b-20">
            <img
              class="title"
              src="assets/img/surf/title.png"
              alt="img-mobile"
            />
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isPolygon" class="row row-xs justify-content-md-center">
      <div class="col-lg-12 pd-lg-t-20">
        <div class="row custom-padding justify-content-center">
          <div class="col-lg-12 farm-title pd-b-20">
            <h3>Connect to Polygon first!</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-xs justify-content-md-center pd-t-20">
      <div class="col-lg-11 pd-lg-t-20 surf-buttons">
        <a href="https://aloha-surf.web.app/" class="btn btn-primary full"
          ><i class="fa fa-expand"></i> Full Screen</a
        >

        <button
          type="button"
          class="btn btn-primary full end"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          How to Play
        </button>
      </div>

      <div class="col-lg-11 pd-lg-t-20">
        <iframe
          src="https://aloha-surf.web.app/"
          class="iframe"
          title="description"
        ></iframe>
      </div>

      <!-- <div class="row p-t-40 pd-b-60">
        <div class="col-lg-12 text-center">
          <h2>20% Discount Presale</h2>
        </div>
        <div class="col-lg-12 text-center">
          <h2>Surf to Earn From 5PM UTC</h2>
        </div>
        <div class="col-lg-12 text-center">
          <h2>Coming Soon!</h2>
        </div>
        <div class="col-lg-12 text-center">
          <br/>
          <div class="btn btn-primary py-1 px-4 mb-2 btn-buy-top" [routerLink]="['/buy-packs']">Buy NFT Player Packs</div>
        </div>
      </div>
      </div> -->
      <div class="col-lg-12">
        <img
          class="controls"
          src="assets/img/surf/controls.png"
          alt="img-mobile"
        />
      </div>

      <div class="col-lg-12">
        <div class="row space-around">
          <div class="col-lg-5 block-stats">
            <div class="row">
              <div class="col-lg-5"></div>
              <div class="col-lg-7 pd-t-20">
                <div class="row p-tb-20">
                  <div class="col-lg-4">
                    <img
                      class="controls"
                      src="assets/img/surf/gold.png"
                      alt="img-mobile"
                    />
                  </div>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-12">
                        <span>1 st Place = <span class="bold">50%</span></span>
                      </div>
                      <div class="col-lg-12">
                        <span>of Prize Fund</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row p-b-20">
                  <div class="col-lg-4">
                    <img
                      class="controls"
                      src="assets/img/surf/silver.png"
                      alt="img-mobile"
                    />
                  </div>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-12">
                        <span>2 nd Place = <span class="bold">35%</span></span>
                      </div>
                      <div class="col-lg-12">
                        <span>of Prize Fund</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row p-b-20">
                  <div class="col-lg-4">
                    <img
                      class="controls"
                      src="assets/img/surf/bronze.png"
                      alt="img-mobile"
                    />
                  </div>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-12">
                        <span>3 rd Place = <span class="bold">15%</span></span>
                      </div>
                      <div class="col-lg-12">
                        <span>of Prize Fund</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pd-t-40 justify-center">
              <div class="col-lg-10">
                <div class="btn-buy" [routerLink]="['/buyAlohaEnvelopes']">
                  Buy NFT Player Packs
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 block-stats">
            <div class="row score-board justify-center">
              <div class="col-lg-12 justify-block pd-t-20">
                <span class="score-title">HIGH SCORES</span><br />
                <span class="score-subtitle">OCTOBER 2021</span>
              </div>

              <div class="col-lg-10 border-dashed">
                <div class="row justify-center">
                  <div class="col-lg-12 p-tb-20" *ngIf="leaderBoard == null">
                    Loading Leaderboard...
                  </div>
                  <div *ngIf="leaderBoard != null" class="p-tb-20 col-lg-12">
                    <div class="row justify-center">
                      <div
                        class="col-lg-12 justify-center"
                        *ngFor="let res of leaderBoard; let i = index"
                      >
                        <div>
                          <img
                            class="monigot"
                            src="assets/img/surf/monigot.png"
                            alt="img-mobile"
                          />
                        </div>
                        <span class="score-row">
                          <span *ngIf="i == 0">{{ i + 1 }}ST:</span>
                          <span *ngIf="i == 1">{{ i + 1 }}ND:</span>
                          <span *ngIf="i == 2">{{ i + 1 }}RD:</span>
                          <span *ngIf="i > 2">{{ i + 1 }}TH:</span>
                          <span
                            >{{ res.address | slice: 0:4 }}-{{
                              res.address | slice: 38:64
                            }}...{{ res.score }}M</span
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row p-tb-20">
              <div class="col-lg-12">
                <div class="row justify-center align-center">
                  <div class="col-lg-3 justify-center">
                    <img
                      class="surfer"
                      src="assets/img/surf/surfer.png"
                      alt="img-mobile"
                    />
                  </div>
                  <div class="col-lg-6 surfer-txt">
                    For playing tips and tricks jump on our Aloha Deﬁ Telegram
                    group now...
                  </div>
                  <div class="col-lg-3 justify-center">
                    <img
                      class="btn-telegram"
                      src="assets/img/surf/btn-telegram.png"
                      alt="img-mobile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="buttons">
    <div class="container">
      <div class="row p-t-20">
        <div class="col-12 text-center">
          <h4>Powered by Polygon</h4>
        </div>
      </div>
    </div>
  </section>

  <section class="partners">
    <div class="container">
      <div class="row p-t-40">
        <div class="col-12 text-center">
          <h2>Partnerships and Affiliates</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="slider-partners">
            <a href="https://www.flowdesk.co/" target="_blank" rel="nofollow">
              <img src="assets/img/landing/partners/flowdesk.png" alt="" />
            </a>
            <a
              href="https://www.dextools.io/app/"
              target="_blank"
              rel="nofollow"
            >
              <img
                src="assets/img/landing/partners/promoted_by_dext.png"
                alt=""
              />
            </a>
            <a
              href="https://www.autonio.foundation/"
              target="_blank"
              rel="nofollow"
            >
              <img
                src="assets/img/landing/partners/autonio-foundation.png"
                alt=""
              />
            </a>
            <a href="https://arkerlabs.com/" target="_blank" rel="nofollow">
              <img src="assets/img/landing/partners/arkerlabs.png" alt="" />
            </a>
          </div>
        </div>
        <div class="col-12">
          <div class="slider-partners">
            <a href="https://ctdsec.com/" target="_blank" rel="nofollow">
              <img
                class="small"
                src="assets/img/landing/partners/ctd-sec.png"
                alt=""
              />
            </a>
            <a href="https://coinbound.io/" target="_blank" rel="nofollow">
              <img src="assets/img/landing/partners/coinbound.png" alt="" />
            </a>
            <a
              href="https://polygon.technology/"
              target="_blank"
              rel="nofollow"
            >
              <img
                class="small"
                src="assets/img/landing/partners/matic.svg"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">
          Surf Invaders - Simply play to earn
        </h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Surf Invaders is the brand new NFT game launched by Aloha Defi. The
          Aloha ambassadors have been transformed into 16bits characters and are
          taking over the waters in an eco-friendly game that promotes
          sustainability and a cleaner environment.
        </p>
        <p>
          The aim of the game is for the players to surf as long as possible
          without falling off their boards or being caught by Fudstapus - the
          giant octopus. On their journey through the waves they are also
          encouraged to collect plastic and clean the ocean behind them.
        </p>

        <p>
          The best players are selected based on the longest distance surfed.
        </p>

        “Up” slows down your surfer to a halt <br />
        “Down” accelerates your surfer <br />
        “Left” and “Right” arrows are used to change the direction of your
        surfer in order to avoid the obstacles <br />
        <br />

        <h3><b>The more you play the more you earn</b></h3>
        <p>
          All players who buy NFT packs to play Surf Invaders will be awarded
          extra NFTs for Surf Invaders V2.
        </p>

        <h3><b>NFT cool off period</b></h3>
        <p>
          The best part of the Surf Invaders game is that players don’t need to
          buy NFTs over and over again in order to play. We have introduced a
          cooling off period, which means that after all lives are played, there
          is a one hour period in which the NFT will recharge and a new life is
          awarded to the player.
        </p>

        <p>
          For more information on how to play
          <a
            target="_blank"
            href="https://alohadefi.medium.com/how-to-play-surf-invaders-surfinvaders-68b379411449"
            >click here</a
          >
        </p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary full"
          data-dismiss="modal"
        >
          Let's Surf!
        </button>
      </div>
    </div>
  </div>
</div>
