import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  constructor() {}

  public slide: 'home' | 'academy' | 'exchanges' = 'home';

  ngOnInit(): void {}

  goToExchanges() {
    this.slide = 'exchanges';
  }

  goToAcademy() {
    this.slide = 'academy';
  }

  back() {
    this.slide = 'home';
  }

  soonOctober() {
    alert('Coming on October!');
  }

  soon() {
    alert('Coming Soon!');
  }

  comingFebruary() {
    alert('Coming Soon!');
  }
}
