<div class="container">
  <div class="row justify-center">
    <div class="col-10">
      <img src="assets/img/landing/banner.gif" alt="" class="img-fluid" />
    </div>
  </div>
</div>

<section class="partners">
  <div class="container">
    <div class="row p-t-20">
      <div class="col-12 text-center">
        <h2>Media talking about Aloha</h2>
      </div>
    </div>

    <div class="row p-t-20 p-b-20 justify-center">
      <div class="col-10 text-center">
        <p>
          Many websites are talking about Aloha, spreading the word about the
          project, it's hard to reach all the people in the crypto world, but
          when you have these great press releases, everything is much easier!
        </p>
      </div>
    </div>

    <div class="row p-t-20">
      <div class="col-12 text-center">
        <h2>Articles about us / Featured in</h2>
      </div>
    </div>

    <div class="row p-t-20 justify-center">
      <div class="col-lg-3 col-md-6 p-b-20">
        <a
          href="https://bitcoinist.com/alohas-play-to-earn-surf-invaders-game-with-an-eco-friendly-twist-launches-on-polygon-this-month/"
          target="_blank"
        >
          <img src="assets/img/media/bitcoinist.png" alt="" class="img-fluid" />
        </a>
      </div>
      <div class="col-lg-3 col-md-6 p-b-20">
        <a
          href="https://cryptopotato.com/alohas-launches-its-play-to-earn-game-surf-invaders-on-polygon/"
          target="_blank"
        >
          <img src="assets/img/media/potato.png" alt="" class="img-fluid" />
        </a>
      </div>
      <div class="col-lg-3 col-md-6 p-b-20">
        <a
          href="https://ambcrypto.com/alohas-play-to-earn-game-with-an-eco-friendly-twist-launches-on-polygon/"
          target="_blank"
        >
          <img src="assets/img/media/amb.png" alt="" class="img-fluid" />
        </a>
      </div>
      <div class="col-lg-3 col-md-6 p-b-20">
        <a
          href="https://cointelegraph.com/news/platform-rides-wave-into-a-defi-revolution-with-user-rewards-for-playing-and-learning"
          target="_blank"
        >
          <img
            src="assets/img/media/cointelegraph.png"
            alt=""
            class="img-fluid"
          />
        </a>
      </div>
      <div class="col-lg-3 col-md-6 p-b-20">
        <a
          href="https://thecapital.io/article/examining-the-recent-nfts-craze-and-what-it-means-for-investors-MV_u8IaCmtVPs3MtSEz"
          target="_blank"
        >
          <img src="assets/img/media/capital.png" alt="" class="img-fluid" />
        </a>
      </div>
      <div class="col-lg-3 col-md-6 p-b-20">
        <a
          href="https://ilkbitcoin.com/aloha-bilgi-ve-kripto-paranizi-odullendiren-learn2earn-modelini-kullaniyor/"
          target="_blank"
        >
          <img src="assets/img/media/ilkbitcoin.png" alt="" class="img-fluid" />
        </a>
      </div>
      <div class="col-lg-3 col-md-6 p-b-20">
        <a
          href="https://kriptoparahaber.com/aloha-bilgi-ve-kripto-paranizi-odullendiren-learn2earn-modelini-kullaniyor.html"
          target="_blank"
        >
          <img src="assets/img/media/haber.png" alt="" class="img-fluid" />
        </a>
      </div>
    </div>

    <div class="row p-t-20">
      <div class="col-12 text-center">
        <h2>Videos about us / Featured in</h2>
      </div>
    </div>

    <div class="row p-t-20 justify-center">
      <div class="col-lg-3 col-md-6 p-b-20 text-center">
        <a href="https://youtu.be/OkY_BpnxttA" target="_blank">
          <img src="assets/img/media/geoid.png" alt="" class="img-fluid" />
        </a>
      </div>
      <div class="col-lg-3 col-md-6 p-b-20 text-center">
        <a href="https://www.youtube.com/watch?v=JS_pK1XhKN4" target="_blank">
          <img src="assets/img/media/giri.png" alt="" class="img-fluid" />
        </a>
      </div>
      <div class="col-lg-3 col-md-6 p-b-20 text-center">
        <a href="https://www.youtube.com/watch?v=-6xvtJq0BwI" target="_blank">
          <img src="assets/img/media/frog.png" alt="" class="img-fluid" />
        </a>
      </div>
      <div class="col-lg-3 col-md-6 p-b-20 text-center">
        <a href="https://www.youtube.com/watch?v=7h3Fr-45fsk" target="_blank">
          <img src="assets/img/media/rick.png" alt="" class="img-fluid" />
        </a>
      </div>
      <div class="col-lg-3 col-md-6 p-b-20 text-center">
        <a href="https://www.youtube.com/watch?v=M6M2p-oaPDQ" target="_blank">
          <img src="assets/img/media/zeus.png" alt="" class="img-fluid" />
        </a>
      </div>
      <div class="col-lg-3 col-md-6 p-b-20 text-center">
        <a href="https://www.youtube.com/watch?v=WCnBK7oPqwQ" target="_blank">
          <img src="assets/img/media/bud.png" alt="" class="img-fluid" />
        </a>
      </div>
    </div>
  </div>
</section>

<section class="partners">
  <div class="container">
    <div class="row p-t-20">
      <div class="col-12 text-center">
        <h2>Partnerships and Affiliates</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="slider-partners">
          <a href="https://www.flowdesk.co/" target="_blank" rel="nofollow">
            <img src="assets/img/landing/partners/flowdesk.png" alt="" />
          </a>
          <a href="https://www.dextools.io/app/" target="_blank" rel="nofollow">
            <img
              class="small"
              src="assets/img/landing/partners/promoted_by_dext.png"
              alt=""
            />
          </a>
          <a
            href="https://www.autonio.foundation/"
            target="_blank"
            rel="nofollow"
          >
            <img
              class="small"
              src="assets/img/landing/partners/autonio-foundation.png"
              alt=""
            />
          </a>
          <a href="https://arkerlabs.com/" target="_blank" rel="nofollow">
            <img src="assets/img/landing/partners/arkerlabs.png" alt="" />
          </a>
        </div>
      </div>
      <div class="col-12">
        <div class="slider-partners">
          <a href="https://ctdsec.com/" target="_blank" rel="nofollow">
            <img
              class="small"
              src="assets/img/landing/partners/ctd-sec.png"
              alt=""
            />
          </a>
          <a href="https://www.xetacapital.com/" target="_blank" rel="nofollow">
            <img src="assets/img/landing/partners/xeta.svg" alt="" />
          </a>
          <a href="https://coinbound.io/" target="_blank" rel="nofollow">
            <img src="assets/img/landing/partners/coinbound.png" alt="" />
          </a>
          <a href="https://polygon.technology/" target="_blank" rel="nofollow">
            <img
              class="small"
              src="assets/img/landing/partners/matic.svg"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="social">
  <div class="container">
    <div class="row p-t-30">
      <div class="col-12 text-center">
        <h2>Say Aloha!</h2>
      </div>
    </div>
    <div class="row icon-social">
      <div class="col-12 text-center">
        <ul class="list-inline list-unstyled mt-3">
          <li class="list-inline-item">
            <a href="https://t.me/AlohaDeFi" target="_blank"
              ><i class="fa fa-telegram" aria-hidden="true"></i
            ></a>
          </li>
          <li class="list-inline-item">
            <a href="https://twitter.com/AlohaDefi" target="_blank"
              ><i class="fa fa-twitter" aria-hidden="true"></i
            ></a>
          </li>
          <li class="list-inline-item">
            <a
              href="https://www.linkedin.com/company/aloha-open-wifi/"
              target="_blank"
              ><i class="fa fa-linkedin" aria-hidden="true"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="buttons">
  <div class="container">
    <div class="row p-t-10">
      <div class="col-12 text-center">
        <h3>Powered by Polygon</h3>
      </div>
    </div>
  </div>
</section>
