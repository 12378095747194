<section class="hot">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <a [routerLink]="['/liquidity-event']">
          <div class="btn-image btn-liquidity liquidity"></div>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <img src="assets/img/landing/banner.gif" alt="" class="img-fluid" />
      </div>
    </div>

    <div class="row p-t-60">
      <div class="col-12 text-center">
        <h1>WHAT’S <b>HOT</b> AT ALOHA...</h1>
      </div>
    </div>

    <div class="row p-t-60">
      <div class="col-12 text-center">
        <img src="assets/img/hot/surf.png" alt="" class="img-fluid" />
      </div>
      <div class="col-12 text-center">
        <div class="row border-blue">
          <div class="col-lg-4 pd-t-60">
            <div class="row p-b-20">
              <div class="col-lg-4">
                <img
                  class="coin"
                  src="assets/img/surf/gold.png"
                  alt="img-mobile"
                />
              </div>
              <div class="col-lg-8 all-center">
                <div class="row coin-txt">
                  <div class="col-lg-12">
                    <span>1st Place = <span class="bold">50%</span></span>
                  </div>
                  <div class="col-lg-12">
                    <span>of Prize Fund</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 pd-t-60">
            <div class="row p-b-20">
              <div class="col-lg-4">
                <img
                  class="coin"
                  src="assets/img/surf/silver.png"
                  alt="img-mobile"
                />
              </div>
              <div class="col-lg-8 all-center">
                <div class="row coin-txt">
                  <div class="col-lg-12">
                    <span>2nd Place = <span class="bold">35%</span></span>
                  </div>
                  <div class="col-lg-12">
                    <span>of Prize Fund</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 pd-t-60">
            <div class="row p-b-20">
              <div class="col-lg-4">
                <img
                  class="coin"
                  src="assets/img/surf/bronze.png"
                  alt="img-mobile"
                />
              </div>
              <div class="col-lg-8 all-center">
                <div class="row coin-txt">
                  <div class="col-lg-12">
                    <span>3rd Place = <span class="bold">15%</span></span>
                  </div>
                  <div class="col-lg-12">
                    <span>of Prize Fund</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 pd-t-20 pd-b-20">
            <span class="plus">PLUS...</span>
          </div>

          <div class="col-lg-12 pd-b-30">
            <span class="text-custom">
              The top 3 players will get their own personalised Avatar </span
            ><br />
            <span class="text-custom">
              designed to be playable in the next months games!
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row pd-t-40 justify-center">
      <div class="col-lg-12 text-center">
        <span class="title-live"> NOW LIVE </span>
      </div>

      <div class="col-lg-11 text-center pd-t-20">
        <span class="txt-blue">
          Our pioneering new NFT-based game -
          <span class="txt-white">‘Surf Invaders’</span> , will feature the
          Aloha ambassador characters in a mind-blowing surf race!
        </span>
      </div>

      <div class="col-lg-11 text-center pd-t-20">
        <span class="txt-white">
          Carve your line through the course, avoid ocean nasties, and pull huge
          airs to ﬁght for a percentage of the prize fund and the title of Surf
          Invaders Champion!
        </span>
      </div>

      <div class="col-lg-11 text-center pd-t-20">
        <span class="txt-white">
          Players can tilt the odds in their favour by picking the right NFTs
          before starting and will need a minimum of two NFTs to play. The more
          NFTs you hold, the more lives you get in the game, up to a maximum of
          ten NFTs. The rarer the NFT, the more lives:
        </span>
      </div>

      <div class="col-lg-11 text-center p-title pd-t-60">
        <p class="title">
          SURF INVADERS<span class="subTitle">: NFT PLAY TO EARN PACKS</span>
        </p>
      </div>

      <div class="col-lg-11 justify-center">
        <div class="row p-b-30">
          <div class="col-lg-4 p-b-30">
            <div class="row">
              <div class="col-lg-12">
                <img src="assets/img/rare-pack.png" alt="" class="deck" />
              </div>
              <div class="col-lg-10">
                <span class="title-pack3">3 PLAYS PACK:</span>
              </div>
              <div class="col-lg-12">
                <span class="season-txt">x2 Regular NFTs</span>
              </div>
              <div class="col-lg-12">
                <span class="season-txt">x1 Rare NFT</span>
              </div>
              <div class="col-lg-12">
                <span class="season-txt">=10K Aloha</span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 p-b-30">
            <div class="row center">
              <div class="col-lg-12">
                <img src="assets/img/ultra-pack.png" alt="" class="deck" />
              </div>
              <div class="col-lg-10 justify-content-left">
                <span class="title-pack4">4 PLAYS PACK:</span>
              </div>
              <div class="col-lg-12">
                <span class="season-txt">x2 Regular NFTs</span>
              </div>
              <div class="col-lg-12">
                <span class="season-txt">x1 Ultra Rare NFT</span>
              </div>
              <div class="col-lg-12">
                <span class="season-txt">=50K Aloha</span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 p-b-30">
            <div class="row center">
              <div class="col-lg-12">
                <img src="assets/img/vip-pack.png" alt="" class="deck" />
              </div>
              <div class="col-lg-10">
                <span class="title-pack5">5 PLAYS PACK:</span>
              </div>
              <div class="col-lg-12">
                <span class="season-txt">x2 Regular NFTs</span>
              </div>
              <div class="col-lg-12">
                <span class="season-txt">x1 Vip Exclusive NFT</span>
              </div>
              <div class="col-lg-12">
                <span class="season-txt">=100K Aloha</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 pd-t-60 all-center">
        <img src="assets/img/hot/dao.png" alt="" class="img-fluid dao-logo" />
        <span class="dao-title p-l-20">
          Participants Can Earn a Cut From Packs
        </span>
      </div>

      <div class="col-lg-11 text-center pd-t-20">
        <span class="txt-white">
          To set things off with a bang, Aloha will host a presale for play
          packs. NFTs from the packs themselves can be used to earn passive
          income in the DAO.
        </span>
      </div>

      <div class="col-lg-11 text-center pd-t-20">
        <span class="txt-white">
          The proceeds of the presale will be split in the following manner:
        </span>
      </div>

      <div class="col-lg-11 pd-t-40 all-center">
        <img src="assets/img/hot/stats.png" alt="" class="img-fluid" />
      </div>

      <div class="col-lg-11 text-center pd-t-20">
        <span class="txt-white">
          This way players give can generate more returns by participating in
          the DAO.
        </span>
      </div>

      <div class="col-lg-11 text-center pd-t-20">
        <span class="txt-white">
          PLUS... <br />
          You can use your collectable NFTS for VIP APY’s on SHAKA (Nov. ‘21)
        </span>
      </div>

      <div class="col-lg-11 text-center pd-t-20">
        <span class="txt-blue big">
          Get ahead of the game and grab an NFT pack from October 1st
        </span>
      </div>

      <div class="col-lg-11 text-center pd-t-60">
        <span class="txt-white">
          We’re super proud to announce a VRF intergration with...
        </span>
      </div>

      <div class="col-lg-6 pd-t-60 all-center">
        <img src="assets/img/hot/link.png" alt="" class="img-fluid" />
      </div>

      <div class="col-lg-11 text-center pd-t-20">
        <span class="txt-white">
          This will power our: <br />
          Aloha NFT Rafﬂes, our upcoming VIP Holiday Rafﬂe and our <br />
          Monthly Random playing NFT Giveaways, first raffle starts 14th October
          2021 !
        </span>
      </div>

      <div class="col-lg-11 text-center pd-t-60">
        <span class="txt-white">
          We’re also super excited to announce our new Marketing Partners...
        </span>
      </div>

      <div class="col-lg-6 pd-t-60 all-center">
        <img src="assets/img/hot/coinbound.png" alt="" class="img-fluid" />
      </div>

      <div class="col-lg-11 text-center pd-t-40">
        <span class="txt-white">
          Coinbound is THE leading cryptocurrency marketing agency, working with
          major crypto brands like eToro, MetaMask, Cosmos, Nexo, OKCoin and
          Voyager to name but a few!
        </span>
      </div>

      <div class="col-lg-11 text-center pd-t-40">
        <span class="txt-white">
          We can’t wait to see what their knowlegde, reach and connections can
          do for Aloha in our continuing mission to boldly go... to the moon!
        </span>
      </div>
    </div>
  </div>
</section>
