import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Web3 from 'web3';
import { environment } from '../../environments/environment';
import { WalletService } from './wallet.service';

declare global {
  interface Window {
    ethereum: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AlohaService {
  MAX_INT = BigInt(
    '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
  );

  maxRarity = 3;

  static txConfirmedSuscriber: any;
  static txCancelledSuscriber: any;

  provider;
  web3;
  address;
  currentChainId;
  rootChainToken;
  childChainToken;
  ERC20PredicateProxy;
  ERC721PredicateProxy;
  goerliChainId = 0x5;
  kovanChainId = 0x2a;
  ethereumChainId = 0x1;
  mumbaiChainId = 0x13881;
  maticChainId = 0x89;
  connectedToRootChain = true;
  rootTunnelData;
  childTunnelData;
  rootProvider;
  childProvider;
  testnetProvider;
  rootChainNFT;
  childChainNFT;
  currentNetworkToken;
  currentNetworkNFT;
  currentNetworkStaking;
  rootChainNftUtils;
  childChainNftUtils;
  currentNetworkChainNftUtils;
  currentNetworkBuyAlohaEnvelopes;
  childAlohaNewPack;
  childAlohaEnvelopes;
  rootChainStaking;
  rootChainStakingV1;
  childChainStaking;
  currentNetwork = 'Ethereum';
  otherNetwork = 'Matic';

  testnetChainToken;
  testnetChainNFT;
  testnetBuyAlohaEnvelopes;
  testnetNftUtils;
  testnetChainStaking;
  testnetChainStakingV1;

  currentAccount: string;

  constructor(private readonly wallet: WalletService) {}

  createObservable(): Observable<any> {
    const observable = new Observable((suscriber) => {
      AlohaService.txConfirmedSuscriber = suscriber;
    });

    return observable;
  }

  createErrorObservable(): Observable<any> {
    const observable = new Observable((suscriber) => {
      AlohaService.txCancelledSuscriber = suscriber;
    });

    return observable;
  }

  async configNetwork(): Promise<void> {
    this.provider = window['ethereum'];
    this.web3 = new Web3(this.provider);

    this.currentChainId = await this.web3.eth.getChainId();

    this.rootChainToken = environment.rootChainToken;
    this.childChainToken = environment.childChainToken;
    this.ERC20PredicateProxy = environment.ERC20PredicateProxy;

    this.rootChainNFT = environment.rootChainNFT;
    this.childChainNFT = environment.childChainNFT;
    this.ERC721PredicateProxy = environment.ERC721PredicateProxy;

    this.rootChainStaking = environment.rootChainStaking;
    this.rootChainStakingV1 = environment.rootChainStakingV1;
    this.childChainStaking = environment.childChainStaking;

    this.rootTunnelData = environment.rootTunnelData;
    this.childTunnelData = environment.childTunnelData;

    this.rootChainNftUtils = environment.rootChainNftUtils;
    this.childChainNftUtils = environment.childChainNftUtils;

    this.childAlohaEnvelopes = environment.childAlohaEnvelopes;
    this.childAlohaNewPack = environment.childAlohaPack;

    this.testnetChainToken = environment.testnetChainToken;
    this.testnetChainNFT = environment.testnetChainNFT;
    this.testnetBuyAlohaEnvelopes = environment.testnetBuyAlohaEnvelopes;
    this.testnetNftUtils = environment.testnetNftUtils;
    this.testnetChainStaking = environment.testnetChainStaking;
    this.testnetChainStakingV1 = environment.testnetChainStakingV1;

    this.currentNetworkToken = this.rootChainToken;
    this.currentNetworkNFT = this.rootChainNFT;
    this.currentNetworkStaking = this.rootChainStaking;
    this.currentNetworkChainNftUtils = this.rootChainNftUtils;

    if (this.currentChainId === this.kovanChainId) {
      this.currentNetworkToken = this.testnetChainToken;
      this.currentNetworkNFT = this.testnetChainNFT;
      this.currentNetworkStaking = this.testnetChainStaking;
      this.currentNetworkChainNftUtils = this.testnetNftUtils;
      this.currentNetworkBuyAlohaEnvelopes = this.testnetBuyAlohaEnvelopes;
      this.connectedToRootChain = true;
      this.currentNetwork = 'Ethereum';
      this.otherNetwork = 'Matic';
    }
    if (this.currentChainId === this.maticChainId) {
      this.currentNetworkToken = this.childChainToken;
      this.currentNetworkNFT = this.childChainNFT;
      this.currentNetworkStaking = this.childChainStaking;
      this.currentNetworkChainNftUtils = this.childChainNftUtils;
      this.currentNetworkBuyAlohaEnvelopes = this.childAlohaEnvelopes;
      this.childAlohaNewPack = this.childAlohaNewPack;
      this.connectedToRootChain = false;
      this.currentNetwork = 'Matic';
      this.otherNetwork = 'Ethereum';
    }

    this.testnetProvider = new Web3.providers.HttpProvider(
      'https://kovan.infura.io/v3/511f4d37e606408d87d9b32b600e722e'
    );
    this.rootProvider = new Web3.providers.HttpProvider(
      'https://mainnet.infura.io/v3/511f4d37e606408d87d9b32b600e722e'
    );
    this.childProvider = new Web3.providers.HttpProvider(
      'https://polygon-mainnet.infura.io/v3/730bdebeb9264fff8477fced48e39f92'
    );
  }

  async getLastPack(): Promise<any> {
    return await this.getLastPackFromAddress(this.currentNetworkBuyAlohaEnvelopes);
  }

  async getLastPackNew(): Promise<any> {
    return await this.getLastPackFromAddress(environment.childAlohaPack);
  }

  async getLastPackFromAddress(address: string): Promise<any> {
    const buyEnvelopesAbi = require('../../assets/abis/BuyAlohaEnvelopes.json');
    const buyEnvelopes = new (this.wallet.getWeb3().eth.Contract)(
      buyEnvelopesAbi,
      address,
    );

    const currentBlock = parseInt(
      await this.wallet.getWeb3().eth.getBlockNumber(),
      undefined
    );
    const lastPack = await buyEnvelopes.getPastEvents(
      'Buyed',
      {
        filter: { buyer: await this.getAccount() },
        fromBlock: currentBlock - 1000,
        toBlock: 'latest',
      },
      (error, events) => {
        return events;
      }
    );
    if (lastPack.length === 0) {
      return null;
    }
    return {
      first: lastPack[lastPack.length - 1].returnValues.reward1,
      second: lastPack[lastPack.length - 1].returnValues.reward2,
      third: lastPack[lastPack.length - 1].returnValues.reward3,
    };
  }

  async buyPack(index: number) {
    const account = await this.getAccount();

    const buyEnvelopesAbi = require('../../assets/abis/BuyAlohaEnvelopes.json');
    const buyEnvelopes = new (this.wallet.getWeb3().eth.Contract)(
      buyEnvelopesAbi,
      this.currentNetworkBuyAlohaEnvelopes
    );

    await buyEnvelopes.methods
      .buyPack(index)
      .send({ from: account })
      .on('confirmation', function (confirmationNumber, receipt) {
        if (AlohaService.txConfirmedSuscriber) {
          if (receipt.status) {
            AlohaService.txConfirmedSuscriber.next(true);
          }
        }
      })
      .on('error', function (error) {
        if (AlohaService.txCancelledSuscriber) {
          if (error.code == 4001) {
            AlohaService.txCancelledSuscriber.next(true);
          }
        }
      });
  }

  async buyNewPack() {
    const account = await this.getAccount();
    const buyPackAbi = require('../../assets/abis/BuyAlohaPack.json');
    const buyPack = new (this.wallet.getWeb3().eth.Contract)(
      buyPackAbi,
      this.childAlohaNewPack
    );

    await buyPack.methods
      .buyPack()
      .send({ from: account });
  }

  async getPackPrice(index: number) {
    const buyEnvelopesAbi = require('../../assets/abis/BuyAlohaEnvelopes.json');
    const buyEnvelopes = new (this.wallet.getWeb3().eth.Contract)(
      buyEnvelopesAbi,
      this.currentNetworkBuyAlohaEnvelopes
    );
    return await buyEnvelopes.methods.getPackPrice(index).call();
  }

  async getNewPackPrice() {
    const buyPackAbi = require('../../assets/abis/BuyAlohaPack.json');
    const buyPack = new (this.wallet.getWeb3().eth.Contract)(
      buyPackAbi,
      this.childAlohaNewPack
    );

    return await buyPack.methods.getPackPrice().call();
  }

  async setImagesByRarity(rarity: number, images: number[]) {
    const account = await this.getAccount();

    const buyEnvelopesAbi = require('../../assets/abis/BuyAlohaEnvelopes.json');
    const buyEnvelopes = new (this.wallet.getWeb3().eth.Contract)(
      buyEnvelopesAbi,
      this.currentNetworkBuyAlohaEnvelopes
    );

    return await buyEnvelopes.methods
      .setImagesByRarity(rarity, images)
      .send({ from: account });
  }

  async getImagesByRarity(rarity: number) {
    const buyEnvelopesAbi = require('../../assets/abis/BuyAlohaEnvelopes.json');
    const buyEnvelopes = new (this.wallet.getWeb3().eth.Contract)(
      buyEnvelopesAbi,
      this.currentNetworkBuyAlohaEnvelopes
    );

    return await buyEnvelopes.methods.getImagesByRarity(rarity).call();
  }

  async getPixelatedImages() {
    const buyEnvelopesAbi = require('../../assets/abis/BuyAlohaEnvelopes.json');
    const buyEnvelopes = new (this.wallet.getWeb3().eth.Contract)(
      buyEnvelopesAbi,
      this.currentNetworkBuyAlohaEnvelopes
    );

    return await buyEnvelopes.methods.getPixelatedImages().call();
  }

  async alohaNFTs(): Promise<object> {
    const account = await this.getAccount();

    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');
    const alohaNFT = new (this.wallet.getWeb3().eth.Contract)(
      alohaNFTAbi,
      this.currentNetworkNFT
    );

    const myNFTs = [];
    const tokens = await this.tokensOfOwner(account);

    for (const token of tokens) {
      myNFTs.push({
        id: token,
        image: await alohaNFT.methods.tokenImage(token).call(),
        background: await alohaNFT.methods.tokenBackground(token).call(),
        rarity: await alohaNFT.methods.tokenRarity(token).call(),
        uri: await alohaNFT.methods.tokenRarity(token).call(),
        external: false,
      });
    }

    return myNFTs;
  }

  async tokensOfOwner(address: string): Promise<[]> {
    const abi = require('../../assets/abis/NFTUtils.json');
    const contract = new (this.wallet.getWeb3().eth.Contract)(
      abi,
      this.currentNetworkChainNftUtils
    );
    const response = await contract.methods
      .tokensOfOwner(this.currentNetworkNFT, address)
      .call();

    return response;
  }

  async alohaNFTsOnMirrorNetwork(): Promise<object> {
    const account = await this.getAccount();
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');

    let web3 = new Web3(this.rootProvider);
    if (this.connectedToRootChain) {
      web3 = new Web3(this.childProvider);
    }

    const alohaNFT = new web3.eth.Contract(
      alohaNFTAbi,
      this.connectedToRootChain ? this.childChainNFT : this.rootChainNFT
    );
    const myNFTs = [];
    const tokens = await this.tokensOfOwner(account);

    for (const token of tokens) {
      myNFTs.push({
        id: token,
        image: await alohaNFT.methods.tokenImage(token).call(),
        background: await alohaNFT.methods.tokenBackground(token).call(),
        rarity: await alohaNFT.methods.tokenRarity(token).call(),
        uri: await alohaNFT.methods.tokenRarity(token).call(),
      });
    }

    return myNFTs;
  }

  getSerieByImage(image: string): string {
    const imageNumber = parseInt(image, undefined);
    if (imageNumber <= 6) {
      return '1';
    } else if (imageNumber <= 14) {
      return '2';
    } else if (imageNumber <= 23) {
      return '3';
    } else if (imageNumber <= 29) {
      return '4';
    } else if (imageNumber <= 38) {
      return '5';
    } else {
      return '6';
    }
  }

  async getNftById(
    id: number,
    network: 'ETH' | 'MATIC'
  ): Promise<{
    owner: string;
    image: string;
    background: string;
    rarity: string;
  }> {
    await this.configNetwork();
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');
    let web3;
    let addr;
    if (network === 'ETH') {
      web3 = this.wallet.getInfuraWeb3();
      addr = this.rootChainNFT;
    } else {
      web3 = this.wallet.getMaticInfuraWeb3();
      addr = this.childChainNFT;
    }
    const alohaNFT = new web3.eth.Contract(alohaNFTAbi, addr);
    return {
      owner: await alohaNFT.methods.ownerOf(id).call(),
      image: await alohaNFT.methods.tokenImage(id).call(),
      background: await alohaNFT.methods.tokenBackground(id).call(),
      rarity: await alohaNFT.methods.tokenRarity(id).call(),
    };
  }

  async lastAlohaNFTsEvents(): Promise<any[]> {
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');
    const alohaNFT = new (this.wallet.getWeb3().eth.Contract)(
      alohaNFTAbi,
      this.currentNetworkNFT
    );

    const lastNFTs = await alohaNFT.getPastEvents(
      'Transfer',
      {
        filter: { from: '0x0000000000000000000000000000000000000000' },
        fromBlock: 0,
        toBlock: 'latest',
      },
      (error, events) => {
        return events;
      }
    );

    const response = [];
    for (const nft of lastNFTs) {
      response.push(nft.returnValues.tokenId);
    }

    return response.reverse();
  }

  async getAllowanceERC721Root(address: string): Promise<boolean> {
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');

    const token = new this.web3.eth.Contract(
      alohaNFTAbi,
      this.currentNetworkNFT
    );

    const allowance = await token.methods
      .isApprovedForAll(address, this.rootTunnelData)
      .call();

    return allowance;
  }

  async getAllowanceERC721Matic(address: string): Promise<boolean> {
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');

    const token = new this.web3.eth.Contract(
      alohaNFTAbi,
      this.currentNetworkNFT
    );

    const allowance = await token.methods
      .isApprovedForAll(address, this.childTunnelData)
      .call();

    return allowance;
  }

  async setApprovalForAllForRoot(): Promise<void> {
    const account = await this.getAccount();
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');

    const token = new this.web3.eth.Contract(
      alohaNFTAbi,
      this.currentNetworkNFT
    );

    await token.methods
      .setApprovalForAll(this.rootTunnelData, true)
      .send({ from: account });
  }

  async transferRootTokenToChild(tokenId: string): Promise<void> {
    const account = await this.getAccount();
    const alohaNFTAbi = require('../../assets/abis/AlohaRootTunnel.json');

    const token = new this.web3.eth.Contract(alohaNFTAbi, this.rootTunnelData);

    await token.methods.transferNFT(tokenId).send({ from: account });
  }

  async transferChildTokenToRoot(tokenId: string): Promise<object> {
    const account = await this.getAccount();
    const alohaNFTAbi = require('../../assets/abis/AlohaChildTunnel.json');

    const token = new this.web3.eth.Contract(alohaNFTAbi, this.childTunnelData);

    return await token.methods.transferNFT(tokenId).send({ from: account });
  }

  async setApprovalForAllMatic(): Promise<void> {
    const account = await this.getAccount();
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');

    const token = new this.web3.eth.Contract(
      alohaNFTAbi,
      this.currentNetworkNFT
    );

    await token.methods
      .setApprovalForAll(this.childTunnelData, true)
      .send({ from: account });
  }

  async lastAlohaNFTs(limit: number): Promise<object> {
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');
    const alohaNFT = new (this.wallet.getMaticInfuraWeb3().eth.Contract)(
      alohaNFTAbi,
      this.childChainNFT
    );
    const totalSupply = await alohaNFT.methods.totalSupply().call();
    const myNFTs = [];

    let max = totalSupply;

    if (totalSupply > limit) {
      max = totalSupply - limit;
    }

    if (totalSupply == 1) {
      max = 0;
    }

    for (let tokenId = totalSupply - 1; tokenId >= max; tokenId--) {
      const tokenbyIndex = await alohaNFT.methods.tokenByIndex(tokenId).call();
      const tokenOwner = await alohaNFT.methods.ownerOf(tokenbyIndex).call();
      if (
        tokenOwner === this.rootTunnelData ||
        tokenOwner === this.childTunnelData
      ) {
        continue;
      }

      myNFTs.push({
        id: tokenbyIndex,
        image: await alohaNFT.methods.tokenImage(tokenbyIndex).call(),
        background: await alohaNFT.methods.tokenBackground(tokenbyIndex).call(),
        rarity: await alohaNFT.methods.tokenRarity(tokenbyIndex).call(),
        uri: await alohaNFT.methods.tokenRarity(tokenbyIndex).call(),
        external: false,
      });
    }

    return myNFTs;
  }

  async getTotalBackgrounds(): Promise<number[]> {
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.currentNetworkStaking
    );

    const total = await alohaStaking.methods.backgrounds().call();
    const response = [];
    for (let index = 1; index <= total; index++) {
      response.push(index);
    }

    return response;
  }

  async getTotalImages(rarity: number): Promise<number> {
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.currentNetworkStaking
    );
    return await alohaStaking.methods.rarityByImagesTotal(rarity).call();
  }

  async isConnectedToMatic(): Promise<boolean> {
    this.currentChainId = await this.web3.eth.getChainId();
    return this.currentChainId === this.maticChainId;
  }

  async getImages(rarity: number): Promise<number[]> {
    this.currentChainId = await this.web3.eth.getChainId();
    if (this.currentChainId === this.maticChainId) {
      const imagesByRarity = {
        1: [
          2, 3, 4, 10, 11, 12, 15, 18, 19, 24, 25, 29, 32, 33, 37, 38, 41, 43,
          50, 53, 54, 62, 64, 67,
        ],
        2: [
          68, 69, 70, 71, 72, 73, 5, 6, 8, 9, 16, 20, 26, 28, 35, 36, 39, 40,
          51, 52, 63, 65, 66,
        ],
        3: [1, 7, 13, 14, 17, 27, 30, 34, 42, 55, 56, 57, 58, 59, 60, 61],
      };
      return imagesByRarity[rarity];
    }

    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.currentNetworkStaking
    );

    const totalImages = await this.getTotalImages(rarity);
    const images = [];
    for (let index = 0; index < totalImages; index++) {
      const image = await alohaStaking.methods
        .rarityByImages(rarity, index)
        .call();

      if (image === '0') {
        break;
      }

      images.push(Number(image));
    }

    return images;
  }

  async getImagesWithLimits(rarity: number): Promise<object> {
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.currentNetworkStaking
    );

    const images = await this.getImages(rarity);
    const response = {};
    for (const image of images) {
      response[image] = {
        limit: await alohaStaking.methods
          .limitByRarityAndImage(image, rarity)
          .call(),
        total: await alohaStaking.methods
          .totalTokensByRarityAndImage(image, rarity)
          .call(),
      };
    }

    return response;
  }

  async getPools(rarity: number): Promise<object> {
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.currentNetworkStaking
    );

    const pools = [];
    let addresses = environment.rootPoolsAddresses;
    if (!this.connectedToRootChain) {
      addresses = environment.childPoolsAddresses;
    }
    for (const poolAddress of addresses) {
      const pool = await alohaStaking.methods
        .poolsMap(poolAddress, rarity)
        .call();

      if (pool.alohaAmount === '0') {
        continue;
      }

      let erc20Token = null;
      if (pool.erc20Amount > 0) {
        const erc20Abi = require('../../assets/abis/AlohaToken.json');
        erc20Token = new (this.wallet.getWeb3().eth.Contract)(
          erc20Abi,
          poolAddress
        );
      }

      pools.push({
        address: poolAddress,
        alohaAmount: this.wallet.getWeb3().utils.fromWei(pool.alohaAmount),
        erc20Symbol:
          pool.erc20Amount > 0
            ? await erc20Token.methods.symbol().call()
            : null,
        erc20Amount:
          pool.erc20Amount > 0
            ? pool.erc20Amount /
              10 ** (await erc20Token.methods.decimals().call())
            : null,
        duration: pool.duration,
        rarity: pool.rarity,
      });
    }

    return pools;
  }

  async pendingAlohaNFTs(): Promise<object> {
    const account = await this.getAccount();
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.currentNetworkStaking
    );

    const myPendingNFTs = [];
    let addresses = environment.rootPoolsAddresses;
    if (!this.connectedToRootChain) {
      addresses = environment.childPoolsAddresses;
    }

    for (const poolAddress of addresses) {
      for (let rarity = 1; rarity <= this.maxRarity; rarity++) {
        const staking = await alohaStaking.methods
          .stakingsMap(account, poolAddress, rarity)
          .call();

        if (staking.tokenImage === '0') {
          continue;
        }

        myPendingNFTs.push({
          id: null,
          image: staking.tokenImage,
          background: staking.tokenBackground,
          rarity,
          uri: null,
          endDate: staking.endDate,
          address: poolAddress,
        });
      }
    }

    return myPendingNFTs;
  }

  async pendingAlohaV1NFTs(): Promise<object> {
    const account = await this.getAccount();
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');

    const web3 = new Web3(this.rootProvider);

    const alohaStaking = new web3.eth.Contract(
      alohaStakingAbi,
      this.rootChainStakingV1
    );

    const myPendingNFTs = [];
    const addresses = environment.rootPoolsAddressesV1;

    for (const poolAddress of addresses) {
      for (let rarity = 1; rarity <= this.maxRarity; rarity++) {
        const staking = await alohaStaking.methods
          .stakingsMap(account, poolAddress, rarity)
          .call();

        if (staking.tokenImage === '0') {
          continue;
        }

        myPendingNFTs.push({
          id: null,
          image: staking.tokenImage,
          background: staking.tokenBackground,
          rarity,
          uri: null,
          endDate: staking.endDate,
          address: poolAddress,
        });
      }
    }

    return myPendingNFTs;
  }

  async pendingAlohaNFTsOnMirrorNetwork(): Promise<object> {
    const account = await this.getAccount();
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');

    let web3 = new Web3(this.rootProvider);
    if (this.connectedToRootChain) {
      web3 = new Web3(this.childProvider);
    }

    const alohaStaking = new web3.eth.Contract(
      alohaStakingAbi,
      this.connectedToRootChain ? this.childChainStaking : this.rootChainStaking
    );

    const myPendingNFTs = [];
    let addresses = environment.rootPoolsAddresses;
    if (this.connectedToRootChain) {
      addresses = environment.childPoolsAddresses;
    }

    for (const poolAddress of addresses) {
      for (let rarity = 1; rarity <= this.maxRarity; rarity++) {
        const staking = await alohaStaking.methods
          .stakingsMap(account, poolAddress, rarity)
          .call();

        if (staking.tokenImage === '0') {
          continue;
        }

        myPendingNFTs.push({
          id: null,
          image: staking.tokenImage,
          background: staking.tokenBackground,
          rarity,
          uri: null,
          endDate: staking.endDate,
          address: poolAddress,
        });
      }
    }

    return myPendingNFTs;
  }

  async approve(address: string): Promise<void> {
    const account = await this.getAccount();
    const erc20Abi = require('../../assets/abis/AlohaToken.json');
    const erc20Token = new (this.wallet.getWeb3().eth.Contract)(
      erc20Abi,
      this.currentNetworkToken
    );

    await erc20Token.methods
      .approve(address, this.MAX_INT)
      .send({ from: account });
  }

  async transfer(address: string, tokenId: string): Promise<void> {
    const account = await this.getAccount();
    const alohaNFTAbi = require('../../assets/abis/AlohaNFT.json');
    const alohaNFT = new (this.wallet.getWeb3().eth.Contract)(
      alohaNFTAbi,
      this.currentNetworkNFT
    );

    await alohaNFT.methods
      .transferFrom(account, address, tokenId)
      .send({ from: account });
  }

  async withdrawV1(address: string, rarity: number): Promise<void> {
    const account = await this.getAccount();
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.rootChainStakingV1
    );

    const addresses = environment.rootPoolsAddressesV1;

    if (address === addresses[0]) {
      await alohaStaking.methods.simpleWithdraw(rarity).send({ from: account });
    } else {
      await alohaStaking.methods
        .pairWithdraw(address, rarity)
        .send({ from: account });
    }
  }

  async withdraw(address: string, rarity: number): Promise<void> {
    const account = await this.getAccount();
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.currentNetworkStaking
    );

    let addresses = environment.rootPoolsAddresses;
    if (!this.connectedToRootChain) {
      addresses = environment.childPoolsAddresses;
    }

    if (address === addresses[0]) {
      await alohaStaking.methods.simpleWithdraw(rarity).send({ from: account });
    } else {
      await alohaStaking.methods
        .pairWithdraw(address, rarity)
        .send({ from: account });
    }
  }

  async forceWithdraw(address: string, rarity: number): Promise<void> {
    const account = await this.getAccount();
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.currentNetworkStaking
    );

    let addresses = environment.rootPoolsAddresses;
    if (!this.connectedToRootChain) {
      addresses = environment.childPoolsAddresses;
    }

    if (address === addresses[0]) {
      await alohaStaking.methods
        .forceSimpleWithdraw(rarity)
        .send({ from: account });
    } else {
      await alohaStaking.methods
        .forcePairWithdraw(address, rarity)
        .send({ from: account });
    }
  }

  async forceWithdrawV1(address: string, rarity: number): Promise<void> {
    const account = await this.getAccount();
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.rootChainStakingV1
    );

    const addresses = environment.rootPoolsAddressesV1;

    if (address === addresses[0]) {
      await alohaStaking.methods
        .forceSimpleWithdraw(rarity)
        .send({ from: account });
    } else {
      await alohaStaking.methods
        .forcePairWithdraw(address, rarity)
        .send({ from: account });
    }
  }

  async getAlohaDecimals() {
    const erc20Abi = require('../../assets/abis/AlohaToken.json');
    const erc20Token = new (this.wallet.getWeb3().eth.Contract)(
      erc20Abi,
      this.currentNetworkToken
    );
    return await erc20Token.methods.decimals().call();
  }

  async allowedAloha(amount: number): Promise<boolean> {
    return this.allowedToken(this.currentNetworkToken, amount);
  }

  async allowedToken(tokenAddress: string, amount: number): Promise<boolean> {
    const account = await this.getAccount();
    const erc20Abi = require('../../assets/abis/AlohaToken.json');
    const erc20Token = new (this.wallet.getWeb3().eth.Contract)(
      erc20Abi,
      tokenAddress
    );

    const decimals = await erc20Token.methods.decimals().call();

    const allowance = await erc20Token.methods
      .allowance(account, this.currentNetworkStaking)
      .call();
    // tslint:disable-next-line: triple-equals
    return allowance >= amount * 10 ** decimals;
  }

  async allowedTokenForEnvelopes(
    tokenAddress: string,
    amount: number
  ): Promise<boolean> {
    const account = await this.getAccount();
    const erc20Abi = require('../../assets/abis/AlohaToken.json');
    const erc20Token = new (this.wallet.getWeb3().eth.Contract)(
      erc20Abi,
      tokenAddress
    );

    const decimals = await erc20Token.methods.decimals().call();

    const allowance = await erc20Token.methods
      .allowance(account, this.currentNetworkBuyAlohaEnvelopes)
      .call();
    // tslint:disable-next-line: triple-equals
    return allowance >= amount * 10 ** decimals;
  }

  async allowedAlohaForNewPack(amount: number): Promise<boolean> {
    const account = await this.getAccount();
    const erc20Abi = require('../../assets/abis/AlohaToken.json');
    const erc20Token = new (this.wallet.getWeb3().eth.Contract)(
      erc20Abi,
      this.currentNetworkToken
    );

    const decimals = await erc20Token.methods.decimals().call();

    const allowance = await erc20Token.methods
      .allowance(account, this.childAlohaNewPack)
      .call();
    // tslint:disable-next-line: triple-equals
    return allowance >= amount * 10 ** decimals;
  }

  async allowedAlohaForBoosters(amount: number): Promise<boolean> {
    return this.allowedTokenForEnvelopes(this.currentNetworkToken, amount);
  }

  async allowedTokenForBoosters(
    tokenAddress: string,
    amount: number
  ): Promise<boolean> {
    const account = await this.getAccount();
    const erc20Abi = require('../../assets/abis/AlohaToken.json');
    const erc20Token = new (this.wallet.getWeb3().eth.Contract)(
      erc20Abi,
      tokenAddress
    );

    const decimals = await erc20Token.methods.decimals().call();

    const allowance = await erc20Token.methods
      .allowance(account, this.currentNetworkBuyAlohaEnvelopes)
      .call();
    // tslint:disable-next-line: triple-equals
    return allowance >= amount * 10 ** decimals;
  }

  async rootChainId(tokenId: string): Promise<number> {
    const abi = require('../../assets/abis/AlohaNFT.json');
    const erc20Token = new (this.wallet.getWeb3().eth.Contract)(
      abi,
      this.currentNetworkNFT
    );

    const rootChainId = await erc20Token.methods
      .tokenRootChainId(tokenId)
      .call();

    return rootChainId;
  }

  async inStake(address: string, rarity: number): Promise<boolean> {
    const account = await this.getAccount();
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.currentNetworkStaking
    );

    const staking = await alohaStaking.methods
      .stakingsMap(account, address, rarity)
      .call();

    // tslint:disable-next-line: triple-equals
    return !(staking.endDate === '0');
  }

  async simpleStake(rarity: number): Promise<void> {
    const account = await this.getAccount();
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.currentNetworkStaking
    );

    await alohaStaking.methods.simpleStake(rarity).send({ from: account });
  }

  async pairStake(address: string, rarity: number): Promise<void> {
    const account = await this.getAccount();
    const alohaStakingAbi = require('../../assets/abis/AlohaStaking.json');
    const alohaStaking = new (this.wallet.getWeb3().eth.Contract)(
      alohaStakingAbi,
      this.currentNetworkStaking
    );

    await alohaStaking.methods
      .pairStake(address, rarity)
      .send({ from: account });
  }

  private async getAccount(): Promise<string | null> {
    if (!this.currentAccount) {
      this.currentAccount = await this.wallet.getAccount();
    }
    return this.currentAccount;
  }

  async getLotteryContract() {
    const abi = require('../../assets/abis/AlohaLottery.json');
    return new (this.wallet.getWeb3().eth.Contract)(
      abi,
      environment.lotteryAddress
    );
  }

  async getCurrentRaffle(): Promise<void> {
    const lotteryContract = await this.getLotteryContract();
    return await lotteryContract.methods.currentRaffleID().call();
  }

  async getTokenNeeded(): Promise<void> {
    const lotteryContract = await this.getLotteryContract();
    const currentRaffleId = await this.getCurrentRaffle();
    return await lotteryContract.methods.token(currentRaffleId).call();
  }

  async getTokenBalance(): Promise<string> {
    const abi = require('../../assets/abis/AlohaToken.json');
    const tokenNeeded = await this.getTokenNeeded();
    const erc20Contract = await new (this.wallet.getWeb3().eth.Contract)(
      abi,
      tokenNeeded
    );

    return await erc20Contract.methods
      .balanceOf(await this.getAccount())
      .call();
  }

  async getTokenSymbol(): Promise<string> {
    const abi = require('../../assets/abis/AlohaToken.json');
    const tokenNeeded = await this.getTokenNeeded();
    const erc20Contract = await new (this.wallet.getWeb3().eth.Contract)(
      abi,
      tokenNeeded
    );

    return await erc20Contract.methods.symbol().call();
  }

  async getTokenDecimals(): Promise<number> {
    const abi = require('../../assets/abis/AlohaToken.json');
    const tokenNeeded = await this.getTokenNeeded();
    const erc20Contract = await new (this.wallet.getWeb3().eth.Contract)(
      abi,
      tokenNeeded
    );

    return await erc20Contract.methods.decimals().call();
  }

  async getBoughtTickets(): Promise<number> {
    const lotteryContract = await this.getLotteryContract();
    const currentRaffleId = await this.getCurrentRaffle();
    return await lotteryContract.methods
      .ticketsBought(await this.getAccount(), currentRaffleId)
      .call();
  }

  async getRaffleInfo(): Promise<void> {
    const lotteryContract = await this.getLotteryContract();
    const currentRaffleId = await this.getCurrentRaffle();
    return await lotteryContract.methods.raffle(currentRaffleId).call();
  }

  async isRaffleClaimed(): Promise<boolean> {
    const lotteryContract = await this.getLotteryContract();
    return await lotteryContract.methods.rewardClaimed().call();
  }

  async buyRaffleTickets(): Promise<void> {
    const lotteryContract = await this.getLotteryContract();
    return await lotteryContract.methods
      .buyTickets()
      .send({ from: await this.getAccount() });
  }

  async claimRaffleReward(): Promise<void> {
    const lotteryContract = await this.getLotteryContract();
    return await lotteryContract.methods
      .claimReward()
      .send({ from: await this.getAccount() });
  }
}
