<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 center p-title">
        <p class="title">
          SURF INVADERS<span class="subTitle">: NFT PLAY TO EARN PACKS</span>
        </p>
      </div>
    </div>

    <div
      *ngIf="loading && isMetamaskConnected && isPolygon"
      class="row justify-content-md-center"
    >
      <div class="col-md-12 col-xl-12">
        <div class="d-flex ht-300 pos-relative align-items-center">
          <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isMetamaskConnected" class="row p-b-30">
      <div class="col-lg-12 center">
        <h2>Connect to metamask first!</h2>
      </div>
    </div>

    <div *ngIf="!isPolygon" class="row p-b-30">
      <div class="col-lg-12 center">
        <h2>Connect to Polygon first!</h2>
      </div>
    </div>

    <div *ngIf="opened" class="row p-b-30 col-12">
      <div class="col-sm-12 col-md-6 col-lg-3 pd-b-20">
        <div class="row txt-center justify-content-md-center">
          <app-aloha-card
            image="{{ imageOne }}"
            rarity="{{ rarityOne }}"
            background="{{ backgroundOne }}"
          ></app-aloha-card>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3 pd-b-20">
        <div class="row txt-center justify-content-md-center">
          <app-aloha-card
            image="{{ imageTwo }}"
            rarity="{{ rarityTwo }}"
            background="{{ backgroundTwo }}"
          ></app-aloha-card>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3 pd-b-20">
        <div class="row txt-center justify-content-md-center">
          <app-aloha-card
            image="{{ imageThree }}"
            rarity="{{ rarityThree }}"
            background="{{ backgroundThree }}"
          ></app-aloha-card>
        </div>
      </div>
    </div>

    <div *ngIf="!loading && isMetamaskConnected" class="row p-b-30">
      <div class="col-lg-4 p-b-30">
        <div class="row center">
          <div class="col-lg-12">
            <img src="assets/img/rare-pack.png" class="deck" alt="" />
          </div>
          <div class="col-lg-12">
            <span class="title-pack3">3 PLAYS PACK:</span>
          </div>
          <div class="col-lg-12">
            <span class="season-txt">x2 Regular NFTs</span>
          </div>
          <div class="col-lg-12">
            <span class="season-txt">x1 Rare NFT</span>
          </div>
          <div class="col-lg-12">
            <span class="season-txt">={{ pack3PriceToShow }}K Aloha</span>
          </div>
          <div class="col-lg-12">
            <button
              class="btn btn-buyEnvelope py-1 px-4 mb-2 mt-3"
              *ngIf="hasPack3Allowance && !pack3Waiting"
              (click)="buyPack(0)"
            >
              Buy Pack
            </button>
            <button
              class="btn btn-approve py-1 px-4 mb-2 mt-3"
              *ngIf="!hasPack3Allowance && !pack3Waiting"
              (click)="approveAloha(0)"
            >
              Approve Aloha's
            </button>
            <button
              class="btn btn-buyEnvelope py-1 px-4 mb-2 mt-3"
              *ngIf="pack3Waiting"
            >
              Wait...
            </button>
          </div>
        </div>
      </div>

      <div class="col-lg-4 p-b-30">
        <div class="row center">
          <div class="col-lg-12">
            <img src="assets/img/ultra-pack.png" class="deck" alt="" />
          </div>
          <div class="col-lg-12 justify-content-left">
            <span class="title-pack4">4 PLAYS PACK:</span>
          </div>
          <div class="col-lg-12">
            <span class="season-txt">x2 Regular NFTs</span>
          </div>
          <div class="col-lg-12">
            <span class="season-txt">x1 Ultra Rare NFT</span>
          </div>
          <div class="col-lg-12">
            <span class="season-txt">={{ pack4PriceToShow }}K Aloha</span>
          </div>
          <div class="col-lg-12">
            <button
              class="btn btn-buyEnvelope py-1 px-4 mb-2 mt-3"
              *ngIf="hasPack4Allowance && !pack4Waiting"
              (click)="buyPack(1)"
            >
              Buy Pack
            </button>
            <button
              class="btn btn-approve py-1 px-4 mb-2 mt-3"
              *ngIf="!hasPack4Allowance && !pack4Waiting"
              (click)="approveAloha(1)"
            >
              Approve Aloha's
            </button>
            <button
              class="btn btn-buyEnvelope py-1 px-4 mb-2 mt-3"
              *ngIf="pack4Waiting"
            >
              Wait...
            </button>
          </div>
        </div>
      </div>

      <div class="col-lg-4 p-b-30">
        <div class="row center">
          <div class="col-lg-12">
            <img src="assets/img/vip-pack.png" class="deck" alt="" />
          </div>
          <div class="col-lg-12">
            <span class="title-pack5">5 PLAYS PACK:</span>
          </div>
          <div class="col-lg-12">
            <span class="season-txt">x2 Regular NFTs</span>
          </div>
          <div class="col-lg-12">
            <span class="season-txt">x1 Vip Exclusive NFT</span>
          </div>
          <div class="col-lg-12">
            <span class="season-txt">={{ pack5PriceToShow }}K Aloha</span>
          </div>
          <div class="col-lg-12">
            <button
              class="btn btn-buyEnvelope py-1 px-4 mb-2 mt-3"
              *ngIf="hasPack5Allowance && !pack5Waiting"
              (click)="buyPack(2)"
            >
              Buy Pack
            </button>
            <button
              class="btn btn-approve py-1 px-4 mb-2 mt-3"
              *ngIf="!hasPack5Allowance && !pack5Waiting"
              (click)="approveAloha(2)"
            >
              Approve Aloha's
            </button>
            <button
              class="btn btn-buyEnvelope py-1 px-4 mb-2 mt-3"
              *ngIf="pack5Waiting"
            >
              Wait...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
