import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent implements OnInit {
  constructor() {}

  public slide: 'home' | 'academy' | 'exchanges' = 'home';

  ngOnInit(): void {}

  goToExchanges() {
    this.slide = 'exchanges';
  }

  goToAcademy() {
    this.slide = 'academy';
  }

  back() {
    this.slide = 'home';
  }

  soonOctober() {
    alert('Coming on October!');
  }

  soon() {
    alert('Coming Soon!');
  }

  comingNovember() {
    alert('Coming in November!');
  }
}
