import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whats-hot',
  templateUrl: './whats-hot.component.html',
  styleUrls: ['./whats-hot.component.scss'],
})
export class WhatsHotComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
