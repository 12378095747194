import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UpdateDynamicInfo {

  constructor(
    private meta: Meta, 
    private title: Title
  ) { }

  updateInfo(title, url, content): void {
    this.updateTitle(title);
    this.updateMeta(url, content);
  }

  /**
   * Update para el titulo de las paginas
   */
  updateTitle(title): void {
    this.title.setTitle(title);
  }

  /**
   * Update metatags para las descripciones de las paginas
   */
   updateMeta(url, content): void {
    // Facebook meta
    this.meta.updateTag({ property: 'og:url', content: url });
    this.meta.updateTag({ property: 'og:description', content: content });

    // Twitter meta
    this.meta.updateTag({ name: 'twitter:description', content: content });

    // Description
    this.meta.updateTag({ name: 'description', content: content });
  }

}