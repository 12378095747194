import { ThrowStmt } from '@angular/compiler';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlohaService } from 'src/app/services/aloha.service';
import { WalletService } from 'src/app/services/wallet.service';
import { MaticPOSClient } from '@maticnetwork/maticjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @ViewChild('addressTo') addressTo: ElementRef;

  id = '...';
  owner = '';
  rarity = '';
  image = '';
  background = '';
  serie = '';
  loading = true;
  external = false;
  account;
  maticPOSClient;
  allowanceERC721Root;
  allowanceERC721Matic;

  network: 'ETH' | 'MATIC' = 'MATIC';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly aloha: AlohaService,
    private readonly walletService: WalletService,
    private readonly router: Router,
  ) { }

  async ngOnInit(): Promise<void> {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.route.snapshot.paramMap.get('network')) {
      this.network = this.route.snapshot.paramMap.get('network').toLowerCase() === 'eth' ? 'ETH' : 'MATIC';
    }
    this.account = await this.walletService.getAccount();
    await this.aloha.configNetwork();
    if (this.account) {
      this.allowanceERC721Root = await this.aloha.getAllowanceERC721Root(this.account);
      this.allowanceERC721Matic = await this.aloha.getAllowanceERC721Matic(this.account);
    }
    this.loadData(this.id);

    this.maticPOSClient = new MaticPOSClient({
      network: 'testnet', // 'mainnet'
      version: 'mumbai', // 'v1'
      parentProvider: (this.aloha.connectedToRootChain) ? this.aloha.provider : this.aloha.rootProvider,
      maticProvider: (this.aloha.connectedToRootChain) ? this.aloha.childProvider : this.aloha.provider
    });
  }

  private async loadData(id: string): Promise<void> {
    const nft = await this.aloha.getNftById(parseInt(id, undefined), this.network);
    this.owner = nft.owner;
    this.rarity = nft.rarity;
    this.image = nft.image;
    this.serie = this.aloha.getSerieByImage(nft.image);
    this.background = nft.background;
    this.loading = false;
    this.external = false;

    if (this.owner === this.aloha.rootTunnelData || this.owner === this.aloha.childTunnelData) {
      this.router.navigateByUrl('/explore');
    }
  }

  async transfer(tokenId): Promise<void> {
    if (this.network === 'ETH' && this.aloha.currentNetwork !== 'Ethereum') {
      alert('Connect to ETH network');
      return;
    }
    if (this.network === 'MATIC' && this.aloha.currentNetwork !== 'Matic') {
      alert('Connect to MATIC network');
      return;
    }
    await this.aloha.transfer(this.addressTo.nativeElement.value, tokenId);
  }

  async transferERC721(tokenId): Promise<void> {
    this.loading = true;

    if (this.aloha.connectedToRootChain) {

      this.aloha.transferRootTokenToChild(tokenId)
      .then(() => {
        alert('Transfer ordered. You will receive your token in about 10 minutes.');
      })
      .finally(() => {
        this.loading = false;
      });

    } else {

      this.aloha.transferChildTokenToRoot(tokenId)
      .then((response: any) => {
        this.saveFromMaticTransferHash(response.transactionHash, this.aloha.rootTunnelData)
        alert('Transfer ordered! Change to "Ethereum" network on Metamask and go to "My NFTs" section in order to claim your token.');
        this.router.navigateByUrl('/collection');
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }

  async allowERC721OnRoot(): Promise<void> {
    this.loading = true;

    await this.aloha.setApprovalForAllForRoot();
    location.reload();

    this.loading = false;
  }

  async allowERC721OnMatic(): Promise<void> {
    this.loading = true;

    await this.aloha.setApprovalForAllMatic();
    location.reload();

    this.loading = false;
  }

  saveFromMaticTransferHash(hash: string, address: string): void {
    const localData: string = localStorage.getItem('txFromMaticHashesNFTs');
    const txHashesNFTs = (JSON.parse(localData) == null) ? [] : JSON.parse(localData);
    txHashesNFTs.push({ hash, address });
    localStorage.setItem('txFromMaticHashesNFTs', JSON.stringify(txHashesNFTs));
  }

}
