export const environment = {
  production: true,
  infuraId: 'b05d6b1ed9c941fe92c6f219abecf70f',

  rootPoolsAddresses: [
    '0x455f7ef6d8bcfc35f9337e85aee1b0600a59fabe', // AlohaToken
  ],

  rootPoolsAddressesV1: [
    '0x455f7ef6d8bcfc35f9337e85aee1b0600a59fabe', // AlohaToken
  ],

  childPoolsAddresses: [
    '0x60AC2E84078Ce30CBC68e3d7b18bCF613271ce6B', // AlohaToken
  ],

  //KOVAN
  testnetChainToken: '0x5F0e8cB5b34b5dCc3Db4AF0b9A874387B5b39e63',
  testnetChainNFT: '0x230029F4b583d3dCCdce1249327A8B14E4c0bDDf',
  testnetBuyAlohaEnvelopes: '0x1C49cAe3d51a7D71512D8dFbd145E81901882dD8',
  testnetNftUtils: '0x684ae2C5f10e24d27301092aAa62F5760033FB33',

  testnetChainStaking: '0xbad3938dd18ee4dc28e7dc8b97fd3b835ebe8d11',
  testnetChainStakingV1: '0xfFb6551Ce6266DF60c585CBbb2938eD2f2612776',
  testnetTunnelData: '0x0D4A1D7F927ff49021B14fc33BECc7aDf359ACCF',

  rootChainToken: '0x455f7ef6d8bcfc35f9337e85aee1b0600a59fabe',
  rootChainNFT: '0x524833d8b9C2194Ead830Fd205E8fAEd9801E776',
  rootChainStaking: '0xacd8be8bce66d34ac2bf215df5655a192e992d7e',
  rootChainStakingV1: '0xa1784f009d990d9b33fac0f5461cac9bcb21d827',
  rootTunnelData: '0x8bedd90e52df8bb663b9ef5c2ca42e7adc27c9b5',
  rootChainNftUtils: '0x11d1816da0c7111aa39145e3509ba1349fffa6b4',

  ERC20PredicateProxy: '0x40ec5B33f54e0E8A33A975908C5BA1c14e5BbbDf',
  ERC721PredicateProxy: '0xE6F45376f64e1F568BD1404C155e5fFD2F80F7AD',

  childChainToken: '0x60AC2E84078Ce30CBC68e3d7b18bCF613271ce6B',
  childChainNFT: '0x75e93ac621b38ae11e5c95a383a37ce8d657b19e',
  childAlohaEnvelopes: '0x1fbe06a33987d7de1b83573e1309dc42efca0028',
  childAlohaPack: '0x2424e0b23155d30a86e5d65c9056f1aa2aa1a759',
  childChainStaking: '0xd293522F73feA71dC1e52e16c01aAF3EEDE72e88',
  childTunnelData: '0x7120d6647B79b437FD78A12bD6580978093A71E1',
  childChainNftUtils: '0xB4b40640fEb2374540b97eb90FdF0AE6dd42c6F5',

  //MUMBAI
  testnetLotteryAddress: '0xE6fB63DF341f5D28A8d0a3f5D5Ff59c02C08F221',
  lotteryAddress: '0x42d0866db6f7728cacb3cd35dd8fd7e1c479d45a',
};
