<!-- START HEADER -->
<header>
  <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
    <a class="navbar-brand" href="/">
      <img src="assets/img/landing/logo2.svg" alt="" class="logo2 mr-4" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarCollapse"
      aria-controls="navbarCollapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" href="/"> Home </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/media']"> Media </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="https://alohadefi.medium.com/"
            target="_blank"
          >
            Blog
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/whats-hot']"> What's hot </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/raffle']"> Raffle </a>
        </li>
        <li *ngIf="hasMetamask" class="nav-item">
          <a href="javascript:;" (click)="switchToMatic()">
            <span class="btn btn-primary py-1 px-4 mb-2">Switch To Matic</span>
          </a>
        </li>
        <li class="nav-item">
          <button
            *ngIf="!address"
            data-toggle="modal"
            data-target="#walletModal"
            class="btn btn-primary py-1 px-4 mb-2"
          >
            Connect Wallet
          </button>

          <button
            *ngIf="address"
            [routerLink]="['/collection']"
            class="btn btn-primary py-1 px-4 mb-2"
          >
            {{ address | slice: 0:4 }}..{{ address | slice: 39:64 }} [{{
              network == "Ethereum" ? "ETH" : "MATIC"
            }}]
          </button>
        </li>
      </ul>
    </div>
  </nav>
</header>

<!-- <div class="slim-navbar" *ngIf="showLayout">
  <div class="container">
    <ul class="nav center">
      <li class="nav-item">
        <a class="none" [routerLink]="'/'">
          <img
            src="assets/img/logo.png"
            class="wd-custom pointer"
            alt="shaka_logo"
          />
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link just-right" [routerLink]="'/stake'">
          <img
            src="assets/img/stake.png"
            class="icon wth-30"
            alt="stake_logo"
          />
          <span>Stake</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'/explore'">
          <img
            src="assets/img/faq.png"
            class="icon wth-30"
            alt="explore_logo"
          />
          <span>Explore</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link just-left" [routerLink]="'/collection'">
          <img
            src="assets/img/stats.png"
            class="icon wth-25"
            alt="my_nfts_logo"
          />
          <span>My NFTs</span>
        </a>
      </li>

      <li class="nav-item" *ngIf="network == 'Ethereum'">
        <a class="nav-link" href="javascript:;" (click)="switchToMatic()">
          <span class="btn-wallet"> SWITCH TO MATIC </span>
        </a>
      </li>

      <li class="nav-item" *ngIf="!address">
        <a class="nav-link">
          <span
            class="btn-wallet"
            data-toggle="modal"
            data-target="#walletModal"
            >Connect wallet</span
          >
        </a>
      </li>
      <li class="nav-item" *ngIf="address">
        <a class="nav-link" href="javascript:;">
          <span class="btn-wallet">
            {{ address | slice: 0:4 }}..{{ address | slice: 39:64 }} [{{
              network == "Ethereum" ? "ETH" : "MATIC"
            }}]
          </span>
        </a>
      </li>
    </ul>
  </div>
</div> -->
<!-- END HEADER -->

<router-outlet></router-outlet>

<div class="footer">
  <div class="img-footer txt-center">
    <img
      class="main-coorp pd-b-40"
      src="assets/img/footer-main.png"
      alt="footer-main"
    />
  </div>
  <img class="img-footer" src="assets/img/footer.png" alt="footer" />
  <span class="copy">Copyright Aloha © 2021</span>
</div>

<div #walletModal id="walletModal" class="modal fade">
  <div class="modal-dialog" role="document">
    <div class="modal-content tx-size-sm">
      <div class="modal-body tx-center pd-y-20 pd-x-20">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <div class="container">
          <div class="row pd-t-20 pd-b-20">
            <div class="col-lg-6">
              <div class="pointer" (click)="connectWithMetamask()">
                <img
                  class="logo-wallet"
                  src="assets/img/metamask.svg"
                  alt="footer-main"
                />
                <p class="wallet-txt">
                  Connect with <br />
                  Metamask
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="pointer" (click)="connectWithWalletConnect()">
                <img
                  class="logo-wallet"
                  src="assets/img/wallet-connect.svg"
                  alt="footer-main"
                />
                <p class="wallet-txt">
                  Connect with <br />
                  Wallet Connect
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
