<section>
  <div class="container">
    <div class="row p-b-30">
      <div class="col-lg-12 center">
        <h2 class="title pad-md">Aloha Limited Edition NFT's</h2>
        <p class="description">
          6 awesome characters to collect and all are Limited Editions to each
          season - <span class="pink">making all NFT’s rare</span>
          (especially as we grow!)
        </p>
      </div>
    </div>

    <hr class="line pink" />

    <div class="row pad-md">
      <div class="col-12 center">
        <p class="text">
          You can obtain NFT’s by either staking or buying - it’s your choice:
        </p>
      </div>
    </div>

    <div class="flex-row p-b-30">
      <div class="p-l-sm center">
        <div class="title small pink capitalize">Stake Aloha</div>
        <div class="text p-b-30">and choose your NFT</div>
        <img
          src="assets/img/buy-packs/stake-aloha.png"
          alt=""
          class="img-fluid img-half-width p-b-20"
        />
        <div class="flex-col">
          <a href="#" [routerLink]="'/stake'">
            <button class="btn btn-primary py-1 px-4 mb-3">
              Go to Staking Area
            </button>
          </a>
        </div>
      </div>
      <div class="p-l-sm center middle">
        <p class="title small pink capitalize">or</p>
      </div>
      <div class="p-l-sm center">
        <div class="title small pink capitalize">Buy NFT pack</div>
        <div class="text">and get Lucky Dip NFTs</div>
        <div class="text blue p-b-30">(Most Popular Option)</div>
        <img
          src="assets/img/buy-packs/nft-pack.png"
          alt=""
          class="img-fluid img-half-width p-b-20"
        />
        <div class="text center p-b-20">Buy pack for 10k ALOHA</div>
        <div class="flex-col">
          <a href="#" [routerLink]="'/buyAlohaNewPack'">
            <button class="btn btn-primary py-1 px-4 mb-3">Buy NFT Pack</button>
          </a>
        </div>
      </div>
    </div>

    <hr class="line pink" />

    <div class="row p-b-30">
      <div class="col-12 center pad-md">
        <p class="subTitle blue">Benefits of Owning Aloha NFTs:</p>
      </div>
    </div>

    <div class="flex-row col-12 p-b-30">
      <div class="p-l-sm center">
        <img
          src="assets/img/buy-packs/aloha-dao.png"
          alt=""
          class="img-fluid img-full-width p-b-20"
        />
        <p class="text center">Voting rights in our DAO Community</p>
      </div>
      <div class="p-l-sm center">
        <img
          src="assets/img/buy-packs/coins.png"
          alt=""
          class="img-fluid img-full-width p-b-20"
        />
        <p class="text center">Share of profits in each platform</p>
      </div>
      <div class="p-l-sm center">
        <img
          src="assets/img/buy-packs/surf-invaders.png"
          alt=""
          class="img-fluid img-full-width p-b-20"
        />
        <p class="text center">Live power in Surf Invaders NFT</p>
      </div>
    </div>

    <div class="row p-b-30">
      <div class="col-12 center">
        <p class="subTitle pink capitalize">Plus</p>
        <p class="text">Vip Access to Special Events</p>
        <p class="text">
          Bigger Discount in IDO and Launchpad IDO’s (Coming Soon!)
        </p>
        <p class="text">Bigger apy in our SHAKA farming platform</p>
      </div>
    </div>
  
  </div>
</section>
