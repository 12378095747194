import { Component, OnInit } from '@angular/core';
import { AlohaService } from 'src/app/services/aloha.service';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss'],
})
export class ExploreComponent implements OnInit {
  lastNFTs;
  numberOfNFTs = 12;
  loading = false;

  constructor(private readonly aloha: AlohaService) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.aloha.configNetwork();
    this.lastNFTs = await this.aloha.lastAlohaNFTs(this.numberOfNFTs);
    this.loading = false;
  }
}
