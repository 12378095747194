<div class="slim-mainpanel">
  <div id="accordion">
    <div class="container">
      <div class="row center">
        <div class="col-lg-12 pd-lg-t-40">
          <div class="row custom-padding justify-content-center">
            <div class="col-lg-12 farm-title pd-b-20">
              <img class="hg-75" src="/assets/img/logo.png" alt="img-mobile" />
            </div>
            <div class="col-lg-12 farm-title title-vip txt-line-vip">
              <span>Aloha NFT Collectables</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row pd-t-20 justify-center">
        <div class="col-md-12 mb-5">
          <div class="row txt-center">
            Created to celebrate the Aloha ETHOS and with a view to promote the
            Aloha Brand mission in a fun way There will be a themed season
            released each month, with all 6 characters taking part Limited
            editions to each season – making all NFT’s rare (especially as we
            grow) Special editions for partnerships will be released to
            celebrate each collaboration. Meet the Ambassadors - Then an image
            of each NTF their description underneath
          </div>
        </div>

        <div class="col-md-3 mb-5">
          <div class="btn-demo txt-center">
            <button
              [routerLink]="['/stake']"
              class="btn btn-oblong btn-aloha btn-block btn-wallet"
            >
              Enter to stake
            </button>
          </div>
        </div>
      </div>

      <div class="row pd-t-20 center justify-between">
        <div class="col-sm-12 col-md-4 col-lg-3 pd-b-20">
          <div class="row">
            <app-aloha-card
              image="1"
              rarity="3"
              background="1"
              head="bodhi"
            ></app-aloha-card>
            <h2 class="title-card">“LIVE LIFE TO THE MAX!”</h2>
            <p class="description">
              Bodhi is one of those larger than life characters who was always
              the popular kid at school. Laid back and friendly, he is always
              the life and soul of the party, and there’s nothing he loves more
              than the ocean and its swell, wind, weather and tides.
            </p>
            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="description large">
                <p>
                  Born in Australia Bodhi grew up surfing, and from a young age
                  he was always encouraged to excel. With his daring spirit and
                  “no limits” attitude he easily progressed to becoming a
                  professional surfer who has competed around the world.
                </p>
                <p>
                  Bodhi is a travelling adventurer who easily makes new friends
                  wherever he goes. With his brilliance and charm there’s
                  nothing to stop him, and a day riding the waves doesn’t mean
                  he can’t find time to keep track of his crypto profits back on
                  the beach.
                </p>
              </div>
            </div>
            <div class="a-action" id="headingOne">
              <a
                href=""
                class="read-more collapsed txt-center"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
                >Read more
                <img src="assets/img/v2/more.png" alt="" class="ml-3"
              /></a>
              <a class="view-type" [routerLink]="['/stake']"
                >View Bodhi’s NFTs</a
              >
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3 pd-b-20">
          <div class="row">
            <app-aloha-card
              image="6"
              rarity="2"
              background="1"
              head="bambi"
            ></app-aloha-card>
            <h2 class="title-card">“SAY IT AS IT IS!”</h2>
            <p class="description">
              Bambi is the life and soul of the party. She’s fun, bubbly, loves
              to dance and is driven by a strong personality. Don’t
              underestimate her, she is a formidable force who plays by her own
              rules. She’ll take charge in any situation and is a natural
              leader.
            </p>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="description large">
                <p>
                  Born in Cape Town, she grew up with a love and respect for the
                  ocean and marine wildlife, and is a hard-core supporter of
                  marine ecology. Her passion is surfing, and she lives to brave
                  the big waves with her friends.
                </p>
                <p>
                  Juggling a day job with study, Bambi knows it’s important to
                  keep her cool, hold her head high and recognises she can turn
                  to the surf if the going gets tough. Her mantra is: ‘Say it as
                  it is’. There’s so much to experience in life, no point in
                  hanging around and missing an opportunity?
                </p>
              </div>
            </div>
            <div class="a-action" id="headingTwo">
              <a
                href=""
                class="read-more collapsed txt-center"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
                >Read more
                <img src="assets/img/v2/more.png" alt="" class="ml-3"
              /></a>
              <a class="view-type" [routerLink]="['/stake']"
                >View Bambi’s NFTs</a
              >
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3 pd-b-20">
          <div class="row">
            <app-aloha-card
              image="5"
              rarity="2"
              background="1"
              head="jin"
            ></app-aloha-card>
            <h2 class="title-card">“FEEL ALIVE!”</h2>
            <p class="description">
              Jin is the ultimate daring spirit and there’s more to him than
              meets the eye. When he was 4 years old, Jin moved from China to
              California where he first learned to surf. Speed is his watch word
              and he’s passionate about riding the waves.
            </p>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordion"
            >
              <div class="description large">
                <p>
                  Confident, evolving and tireless, Jin now lives in Hawaii,
                  arguably one of the best surfing destinations in the world
                  where he practices all year round.
                </p>
                <p>
                  With his skills and determination there’s nothing to stop him,
                  and he is your perfect partner for a surfing adventure.
                </p>
                <p>
                  He’s the kind of friend who’s hard to keep up with, and
                  however far-out and extreme his pursuit of adrenaline and
                  excitement, one thing is certain, hanging with Jin will always
                  always make you feel alive.
                </p>
              </div>
            </div>
            <div class="a-action" id="headingThree">
              <a
                href=""
                class="read-more collapsed txt-center"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
                >Read more
                <img src="assets/img/v2/more.png" alt="" class="ml-3"
              /></a>
              <a class="view-type" [routerLink]="['/stake']">View Jin’s NFTs</a>
            </div>
          </div>
        </div>
      </div>

      <div class="row pd-b-40">
        <div class="col-lg-12">
          <div class="row custom-padding justify-content-center">
            <div
              class="col-lg-12 farm-title title-vip title-vip-2 txt-line-vip"
            >
              <ul class="list-inline ul-icon">
                <li class="list-inline-item">
                  <img src="assets/img/v2/corona.png" alt="" />
                </li>
                <li class="list-inline-item">
                  <img src="assets/img/v2/estrella.png" alt="" />
                </li>
                <li class="list-inline-item">
                  <img src="assets/img/v2/luna.png" alt="" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row pd-t-20 center justify-between">
        <div class="col-sm-12 col-md-4 col-lg-3 pd-b-20">
          <div class="row">
            <app-aloha-card
              image="4"
              rarity="1"
              background="1"
              head="jazz"
            ></app-aloha-card>
            <h2 class="title-card">“KNOW WHAT YOU WANT”</h2>
            <p class="description">
              Jazz is smart, savvy and bold. She knows what she wants and is
              fully prepared to go after it. Born in Sri Lanka, Jazz spent her
              childhood on the south beaches of Hikkaduwa where she developed
              her love for the ocean.
            </p>
            <div
              id="collapseFour"
              class="collapse"
              aria-labelledby="headingFour"
              data-parent="#accordion"
            >
              <div class="description large">
                <p>
                  A self-made woman, she’s always looking for new opportunity
                  and adventure, and with a degree in Marine Biology, Jazz knows
                  she could be equally successful in business as she is on the
                  surfboard.
                </p>
                <p>
                  A demon surfer and fierce networker, who she doesn’t know
                  really isn’t worth knowing, and when isn’t online managing her
                  crypto investments, she is either working, partying or
                  shopping.
                </p>
                <p>
                  With a strong “you only live once” attitude, she’s not afraid
                  to spend her crypto profits in pursuit of living life to the
                  max.
                </p>
              </div>
            </div>
            <div class="a-action" id="headingFour">
              <a
                href=""
                class="read-more collapsed txt-center"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
                >Read more
                <img src="assets/img/v2/more.png" alt="" class="ml-3"
              /></a>
              <a class="view-type" [routerLink]="['/stake']"
                >View Jazz’s NFTs</a
              >
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3 pd-b-20">
          <div class="row">
            <app-aloha-card
              image="3"
              rarity="1"
              background="1"
              head="duke"
            ></app-aloha-card>
            <h2 class="title-card">“THERE’S MORE OUT THERE THAN WE KNOW”</h2>
            <p class="description">
              The youngest of the Aloha family is Duke and he’s fearless. Duke
              loves life with a passion but always puts his friends and family
              first. Loyal, witty and sometimes a little naïve, Duke lives for
              the rhythm of sound and surf.
            </p>
            <div
              id="collapseFive"
              class="collapse"
              aria-labelledby="headingFive"
              data-parent="#accordion"
            >
              <div class="description large">
                <p>
                  A successful international DJ, he still grabs every
                  opportunity to trade the decks for a surfboard, cutting up the
                  biggest kick ass waves.
                </p>
                <p>
                  Born in Brazil, Duke rents an apartment in Rio where he lives
                  life free from worries, with a strong belief that you can do
                  pretty much anything if you put your mind to it.
                </p>
                <p>
                  Duke is a born entrepreneur, and with the world already pretty
                  much at his fingertips, he still feels there is so much more
                  out there and has a thirst for learning, exploring and
                  developing between time spent with his music and the waves.
                </p>
              </div>
            </div>
            <div class="a-action" id="headingFire">
              <a
                href=""
                class="read-more collapsed txt-center"
                data-toggle="collapse"
                data-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
                >Read more
                <img src="assets/img/v2/more.png" alt="" class="ml-3"
              /></a>
              <a class="view-type" [routerLink]="['/stake']"
                >View Duke’s NFTs</a
              >
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-3 pd-b-20">
          <div class="row">
            <app-aloha-card
              image="2"
              rarity="1"
              background="1"
              head="daisy"
            ></app-aloha-card>
            <h2 class="title-card">“STAND UP FOR WHAT YOU BELIEVE IN”</h2>
            <p class="description">
              Growing up, Daisy has always been interested in nature, and with a
              deep appreciation of everything around her, she’s a passionate
              campaigner and is very mindful of the world she lives in and where
              she fits in.
            </p>
            <div
              id="collapseSix"
              class="collapse"
              aria-labelledby="headingSix"
              data-parent="#accordion"
            >
              <div class="description large">
                <p>
                  Born in Stockholm, Daisy loves to spend her summers alongside
                  the big wave experts and surfing community in El Tunco, El
                  Salvador.
                </p>
                <p>
                  She fervently supports the environment and volunteers with a
                  wildlife conservation NGO. Her special focus is protecting the
                  marine turtles nesting in El Salvador.
                </p>
                <p>
                  Daisy is not afraid to stand up for what she believes in, and
                  is quick to defend her friends and anybody who shares her
                  beliefs. Once an ally, Daisy is a friend for life and will
                  always bring fun and positivity into any social circle.
                </p>
              </div>
            </div>
            <div class="a-action" id="headingFire">
              <a
                href=""
                class="read-more collapsed txt-center"
                data-toggle="collapse"
                data-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
                >Read more
                <img src="assets/img/v2/more.png" alt="" class="ml-3"
              /></a>
              <a class="view-type" [routerLink]="['/stake']"
                >View Daisy’s NFTs</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
