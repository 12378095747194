import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { AlohaService } from 'src/app/services/aloha.service';
import { MathService } from 'src/app/services/math.service';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-buy-aloha-new-pack',
  templateUrl: './buy-aloha-new-pack.component.html',
  styleUrls: ['./buy-aloha-new-pack.component.scss'],
})
export class BuyAlohaNewPackComponent implements OnInit {
  account;
  loading: boolean = true;
  isMetamaskConnected: boolean = false;
  packWaiting: boolean = false;
  haspackAllowance: boolean = false;
  packPrice;
  packPriceToShow;
  opened = false;
  isPolygon = true;

  // Pack Results
  imageOne;
  rarityOne;
  backgroundOne;
  imageTwo;
  rarityTwo;
  backgroundTwo;
  imageThree;
  rarityThree;
  backgroundThree;

  constructor(
    private aloha: AlohaService,
    private wallet: WalletService,
    private cdr: ChangeDetectorRef,
    private math: MathService
  ) {}

  ngOnInit(): void {
    const observable = this.aloha.createObservable();
    const errorObservable = this.aloha.createErrorObservable();

    errorObservable.pipe(take(1)).subscribe((value) => {
      if (value) {
        this.packWaiting = false;
        this.cdr.detectChanges();
      }
    });

    observable.pipe(take(1)).subscribe((value) => {
      if (value) {
        this.packWaiting = false;
        this.cdr.detectChanges();
      }
    });

    this.loadData();
  }

  async getPrices() {
    let decimals = await this.aloha.getAlohaDecimals();
    this.packPrice = await this.aloha.getNewPackPrice();
    this.packPriceToShow =
      this.math.toHumanValue(this.packPrice, decimals) / 1000;
  }

  async checkNetwork() {
    this.isPolygon = await this.aloha.isConnectedToMatic();
  }

  async loadData() {
    this.checkNetwork();
    this.account = await this.wallet.getAccount();
    if (this.account != null) {
      this.isMetamaskConnected = true;
    }

    if (this.isMetamaskConnected) {
      await this.aloha.configNetwork();
      await this.checkAllowances();
      await this.getPrices();
      this.loading = false;
    }
  }

  async checkAllowances() {
    const packPrice = await this.aloha.getNewPackPrice();
    this.haspackAllowance = await this.aloha.allowedAlohaForNewPack(packPrice);
  }

  async approveAloha() {
    this.packWaiting = true;
    try {
      await this.aloha.approve(this.aloha.childAlohaNewPack);
    } catch (e) {
      console.error(e);
    }
    await this.checkAllowances();
    this.packWaiting = false;
  }

  async buyPack() {
    this.opened = false;
    this.packWaiting = true;
    await this.aloha.buyNewPack();
    await this.showLastPack();
    this.packWaiting = false;
  }

  async showLastPack() {
    const pack = await this.aloha.getLastPackNew();
    const first = await this.aloha.getNftById(pack.first, 'MATIC');
    this.imageOne = first.image;
    this.backgroundOne = first.background;
    this.rarityOne = first.rarity;
    const second = await this.aloha.getNftById(pack.second, 'MATIC');
    this.imageTwo = second.image;
    this.backgroundTwo = second.background;
    this.rarityTwo = second.rarity;
    const third = await this.aloha.getNftById(pack.third, 'MATIC');
    this.imageThree = third.image;
    this.backgroundThree = third.background;
    this.rarityThree = third.rarity;
    this.opened = true;
  }
}
