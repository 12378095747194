import { Injectable } from '@angular/core';
import { create, all } from 'mathjs';

@Injectable({
  providedIn: 'root',
})
export class MathService {

  private math = create(all, {
    number: 'BigNumber',
    precision: 64,
  });

  toHumanValue(amount: string, decimals: number): number {
    return parseFloat(
      this.math
        .chain(this.math.bignumber(amount))
        .divide(this.math.bignumber(10).pow(this.math.bignumber(decimals)))
        .done()
        .toFixed(4)
    );
  }

  toBlockchainValue(amount: string, decimals: number): string {
    return this.math.format(
      this.math
        .chain(this.math.bignumber(amount))
        .multiply(this.math.bignumber(10).pow(this.math.bignumber(decimals)))
        .done(),
      { notation: 'fixed' }
    );
  }
}

