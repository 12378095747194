<div class="container">
  <div class="row justify-center">
    <div class="col-10">
      <img src="assets/img/landing/banner.gif" alt="" class="img-fluid" />
    </div>
  </div>
</div>

<section class="buttons">
  <div class="container">
    <div class="row p-t-10">
      <div class="col-12 text-center">
        <a [routerLink]="['/buyAlohaNewPack']">
          <h2>Buy limited edition NFTs</h2>
        </a>
      </div>
    </div>

    <div *ngIf="slide == 'home'">
      <div class="row justify-center">
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a [routerLink]="['/buyAlohaNewPack']">
                <img
                  src="assets/img/buttons/button-buy-nft.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a [routerLink]="['/buyAlohaNewPack']">
                <img
                  src="assets/img/buttons/button-buy-nft.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a [routerLink]="['surf']">
                <img
                  src="assets/img/buttons/button-play.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a [routerLink]="['surf']">
                <img
                  src="assets/img/buttons/button-play.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a [routerLink]="['nft']">
                <img
                  src="assets/img/buttons/button-browse.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a [routerLink]="['nft']">
                <img
                  src="assets/img/buttons/button-browse.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a (click)="comingFebruary()">
                <img
                  src="assets/img/buttons/button-stake.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a (click)="comingFebruary()">
                <img
                  src="assets/img/buttons/stake-back.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a href="javascript:void(0)" (click)="goToAcademy()">
                <img
                  src="assets/img/buttons/button-academy.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a href="javascript:void(0)" (click)="goToAcademy()">
                <img
                  src="assets/img/buttons/button-academy.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a target="_blank" href="https://dao.alohadefi.io/">
                <img
                  src="assets/img/buttons/button-dao.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a target="_blank" href="https://dao.alohadefi.io/">
                <img
                  src="assets/img/buttons/button-dao.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="slide == 'exchanges'">
      <div class="row">
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a
                target="_blank"
                href="https://app.uniswap.org/#/swap?use=V2&outputCurrency=0x455f7ef6d8bcfc35f9337e85aee1b0600a59fabe"
              >
                <img
                  src="assets/img/buttons/exchanges/uniswap.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a
                target="_blank"
                href="https://app.uniswap.org/#/swap?use=V2&outputCurrency=0x455f7ef6d8bcfc35f9337e85aee1b0600a59fabe"
              >
                <img
                  src="assets/img/buttons/exchanges/uniswap.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a
                target="_blank"
                href="https://swap.smartdex.app/#/swap?inputCurrency=0x60ac2e84078ce30cbc68e3d7b18bcf613271ce6b&outputCurrency=0xad684e79ce4b6d464f2ff7c3fd51646892e24b96"
              >
                <img
                  src="assets/img/buttons/exchanges/smardex.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a
                target="_blank"
                href="https://swap.smartdex.app/#/swap?inputCurrency=0x60ac2e84078ce30cbc68e3d7b18bcf613271ce6b&outputCurrency=0xad684e79ce4b6d464f2ff7c3fd51646892e24b96"
              >
                <img
                  src="assets/img/buttons/exchanges/smardex.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a
                target="_blank"
                href="https://www.probit.com/app/exchange/ALOHA-ETH"
              >
                <img
                  src="assets/img/buttons/exchanges/probit.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a
                target="_blank"
                href="https://www.probit.com/app/exchange/ALOHA-ETH"
              >
                <img
                  src="assets/img/buttons/exchanges/probit.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a
                target="_blank"
                href="https://www.hotbit.io/exchange?symbol=ALOHA_USDT"
              >
                <img
                  src="assets/img/buttons/exchanges/hotbit.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a
                target="_blank"
                href="https://www.hotbit.io/exchange?symbol=ALOHA_USDT"
              >
                <img
                  src="assets/img/buttons/exchanges/hotbit.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a
                target="_blank"
                href="https://opensea.io/collection/aloha-nft-v3"
              >
                <img
                  src="assets/img/buttons/exchanges/opensea.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a
                target="_blank"
                href="https://opensea.io/collection/aloha-nft-v3"
              >
                <img
                  src="assets/img/buttons/exchanges/opensea.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a [routerLink]="['buy-packs']">
                <img
                  src="assets/img/buttons/exchanges/booster.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a [routerLink]="['buy-packs']">
                <img
                  src="assets/img/buttons/exchanges/booster.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="slide == 'academy'">
      <div class="row">
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a
                target="_blank"
                href="https://academy.alohawifi.app/workshop.php"
              >
                <img
                  src="assets/img/buttons/videos/1.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a
                target="_blank"
                href="https://academy.alohawifi.app/workshop.php"
              >
                <img
                  src="assets/img/buttons/videos/1.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=7x7yedsHkeg"
              >
                <img
                  src="assets/img/buttons/videos/2.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=7x7yedsHkeg"
              >
                <img
                  src="assets/img/buttons/videos/2.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=qq4SB_jCvkY"
              >
                <img
                  src="assets/img/buttons/videos/3.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=qq4SB_jCvkY"
              >
                <img
                  src="assets/img/buttons/videos/3.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=qk15hWvpGe8"
              >
                <img
                  src="assets/img/buttons/videos/4.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=qk15hWvpGe8"
              >
                <img
                  src="assets/img/buttons/videos/4.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=GCNhkT8u720"
              >
                <img
                  src="assets/img/buttons/videos/5.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=GCNhkT8u720"
              >
                <img
                  src="assets/img/buttons/videos/5.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 p-t-20 flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=_aqWz-aqSqk"
              >
                <img
                  src="assets/img/buttons/videos/6.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
            <div class="flip-card-back">
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=_aqWz-aqSqk"
              >
                <img
                  src="assets/img/buttons/videos/6.png"
                  alt=""
                  class="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="slide != 'home'" class="row justify-center center p-t-30">
      <span href="javascript:void(0)" (click)="back()" class="back"
        >Go Back</span
      >
    </div>
  </div>
</section>

<section class="video">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h1>What is Aloha DeFi?</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/igpkC45etTM"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-5">
        <p>
          Aloha DeFi is your gateway to the DeFi world. With NFTs, staking, and
          a DAO platform that utilizes NFTs. Earn platform rewards by
          participating in the DAO. All at near-instant speeds and negligible
          transaction fees.
        </p>
        <p>
          You can do all of this and more today with our mainnet product. Our
          community is already busy nabbing some rare NFTs through staking. Our
          Aloha app also lets you earn tokens by sharing your mobile bandwidth.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-xs-12">
        <a
          target="_blank"
          href="https://app.uniswap.org/#/swap?use=V2&outputCurrency=0x455f7ef6d8bcfc35f9337e85aee1b0600a59fabe"
        >
          <div class="btn-image buy"></div>
        </a>
      </div>
      <div class="col-lg-4 col-xs-12">
        <a href="/nft">
          <div class="btn-image viewNft"></div>
        </a>
      </div>
      <div class="col-lg-4 col-xs-12">
        <a href="https://t.me/AlohaDeFi" target="_blank">
          <div class="btn-image join"></div>
        </a>
      </div>
    </div>
  </div>
</section>

<div class="container p-t-60">
  <div class="row">
    <div class="col-12">
      <a href="https://www.travala.com?ref=martinregan" target="_blank">
        <img
          src="assets/img/landing/banner-2.png"
          alt="travala"
          class="img-fluid"
      /></a>
    </div>
  </div>
</div>

<section class="why">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6">
        <h2>Why Choose Aloha NFTs?</h2>
        <p>
          NFTs have exploded onto the scene this yearwith vast potential rewards
          for everyoneinvolved. So why choose Aloha NFTs?
        </p>
        <ul>
          <li>
            <p class="mb-0">Limited amounts each month – great collectable</p>
          </li>
          <li>
            <p class="mb-0">Swap or sell in the future on multiple platforms</p>
          </li>
          <li><p class="mb-0">Use for voting power in the DAO</p></li>
          <li>
            <p class="mb-0">
              NFT holders have exclusive benefits to Aloha platforms
            </p>
          </li>
        </ul>
      </div>
      <div class="col-12 col-lg-6">
        <img src="assets/img/landing/cartas.png" alt="" class="img-fluid" />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-xs-12">
        <a href="/stake">
          <div class="btn-image earnNft"></div>
        </a>
      </div>

      <div class="col-lg-4 col-xs-12">
        <a href="https://t.me/AlohaDeFi" target="_blank">
          <div class="btn-image questions"></div>
        </a>
      </div>

      <div class="col-lg-4 col-xs-12">
        <a href="/nft">
          <div class="btn-image viewNft"></div>
        </a>
      </div>
    </div>
  </div>
</section>

<section class="staking">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-5">
        <img
          src="assets/img/landing/staking-nfts.png"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="col-12 col-lg-7">
        <h2>Staking NFTs</h2>
        <p>
          Aloha NFTs are particularly special because you can actually use them
          in the governance platform! Each NFT is worth a certain number of
          votes, depending on the rarity of the NFT.
        </p>
        <p>
          You can buy Aloha NFTs on the Open Sea marketplace or stake your ALOHA
          tokens to earn them. Once you’ve got your first NFTs, you’ll be able
          to stake them via a quick process. Our integration with Polygon means
          that the process is quick and inexpensive. Aloha NFTs are
          multi-functional!
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-xs-12">
        <a href="/stake">
          <div class="btn-image startStake"></div>
        </a>
      </div>
      <div class="col-lg-4 col-xs-12">
        <a href="https://youtu.be/GCNhkT8u720" target="_blank">
          <div class="btn-image viewNow"></div>
        </a>
      </div>
      <div class="col-lg-4 col-xs-12">
        <a href="/nft">
          <div class="btn-image viewNft"></div>
        </a>
      </div>
    </div>
  </div>
</section>

<section class="alohaDao">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-7">
        <h2>Aloha DAO</h2>
        <p>
          A Decentralized Autonomous Organization is a decentralized governing
          body where the participants have full control in determining changes
          to the network.
        </p>
        <ul>
          <li>
            <p class="mb-0">Submit proposals for the project development</p>
          </li>
          <li><p class="mb-0">Vote on other proposals</p></li>
          <li><p class="mb-0">Help to steer Aloha to success</p></li>
          <li><p class="mb-0">Take your share of the platform profits</p></li>
        </ul>
      </div>
      <div class="col-12 col-lg-5">
        <img src="assets/img/landing/aloha-dao.png" alt="" class="img-fluid" />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-xs-12">
        <a
          href="https://alohadefi.medium.com/how-to-participate-in-the-aloha-dao-2354776632aa"
          target="_blank"
        >
          <div class="btn-image how"></div>
        </a>
      </div>
      <div class="col-lg-4 col-xs-12">
        <a href="https://youtu.be/qk15hWvpGe8" target="_blank">
          <div class="btn-image viewNow"></div>
        </a>
      </div>
      <div class="col-lg-4 col-xs-12">
        <a href="https://dao.alohadefi.io/" target="_blank">
          <div class="btn-image launch"></div>
        </a>
      </div>
    </div>
  </div>
</section>

<section class="roadmap">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 text-center">
        <h2>Info & Downloads</h2>
      </div>
      <div class="col-lg-3 col-xs-12">
        <a
          href="https://www.youtube.com/channel/UCJ-L1_B0PP6fwk3lTbX_ELw"
          target="_blank"
        >
          <div class="btn-image video"></div>
        </a>
      </div>
      <div class="col-lg-3 col-xs-12">
        <a href="/assets/documents/Presentation.pdf" target="_blank">
          <div class="btn-image whitepaper"></div>
        </a>
      </div>
      <div class="col-lg-3 col-xs-12">
        <a href="/assets/documents/DeFi.pdf" target="_blank">
          <div class="btn-image onepager"></div>
        </a>
      </div>
      <div class="col-lg-3 col-xs-12">
        <a href="/assets/documents/OnePager.pdf" target="_blank">
          <div class="btn-image litepaper"></div>
        </a>
      </div>
    </div>
  </div>

  <div class="container p-t-60">
    <div class="row">
      <div class="col-12 text-center">
        <h2>Aloha’s 2021/22 Roadmap</h2>
      </div>
    </div>
    <div class="row justify-center">
      <div class="col-12 col-md-3 contents p-b-40">
        <img
          src="assets/img/landing/roadmap-1.png"
          alt=""
          class="img-fluid img-p"
        />
        <div class="box mt-5">
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Public Sale
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Listed on HotBit
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Listed on Problt
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />NFT Staking Platform
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Opened DAO
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Launched on Polygon
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Partnership with Autonio
          </p>
        </div>
      </div>
      <div class="col-12 col-md-3 contents p-b-40">
        <img
          src="assets/img/landing/Roadmap-02.png"
          alt=""
          class="img-fluid img-p"
        />
        <div class="box mt-5">
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Developed Aloh4Business
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Play2Earn Surf Invaders
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Aloha Academy
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Integrated Chainlink VRF
          </p>
        </div>
      </div>
      <div class="col-12 col-md-3 contents p-b-40">
        <img
          src="assets/img/landing/Roadmap-03.png"
          alt=""
          class="img-fluid img-p"
        />
        <div class="box mt-5">
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Open Aloha Academy
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Upgrade Surf Invaders
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Beta testing Aloha App
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Marketing Aloha4Business
          </p>
        </div>
      </div>
      <div class="col-12 col-md-3 contents p-b-40">
        <img
          src="assets/img/landing/Roadmap-04.png"
          alt=""
          class="img-fluid img-p"
        />
        <div class="box mt-5">
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Launch Shaka Farm
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Open Micro Dex
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Develop AlohaLand VR
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Launch Aloha4Business
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Open Aloha Market Placce
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Open Global Partner program
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Expand Aloha Academy
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Explore Tier 1 CEX
          </p>
          <p>
            <img
              src="assets/img/landing/img-li.svg"
              alt=""
              class="img-li mr-2"
            />Confirm Stratic Parnerships
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="team p-t-40 p-b-20">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h2>The Aloha Dream Team</h2>
      </div>
    </div>
    <div class="row justify-center p-t-30 p-b-20">
      <div class="col-11">
        <div class="row justify-center">
          <div class="text-center profile col-lg-3">
            <img src="assets/img/landing/team/martin.png" alt="" />
            <p class="name">Martin Regan</p>
            <span>Entreprenuer & CEO</span>
          </div>
          <div class="text-center profile col-lg-3">
            <img src="assets/img/landing/team/phillip.png" alt="" />
            <p class="name">Phillip Legg</p>
            <span>CTO</span>
          </div>
          <div class="text-center profile col-lg-3">
            <img src="assets/img/landing/team/casey.png" alt="" />
            <p class="name">Casey Blanche</p>
            <span>Head of Operations</span>
          </div>
          <div class="text-center profile col-lg-3">
            <img src="assets/img/landing/team/tobias.png" alt="" />
            <p class="name">Tobias Kirmes</p>
            <span>Operations</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-center p-t-30 p-b-20">
      <div class="col-11">
        <div class="row justify-center">
          <div class="text-center profile col-lg-3">
            <img src="assets/img/landing/team/mark.png" alt="" />
            <p class="name">Mark Johnson</p>
            <span>Finance</span>
          </div>
          <div class="text-center profile col-lg-3">
            <img src="assets/img/landing/team/juanjo.png" alt="" />
            <p class="name">Juanjo Chust</p>
            <span>Blockchain Senior Dev</span>
          </div>
          <div class="text-center profile col-lg-3">
            <img src="assets/img/landing/team/nelson.png" alt="" />
            <p class="name">Nelson Marco</p>
            <span>Blockchain Dev</span>
          </div>
          <div class="text-center profile col-lg-3">
            <img src="assets/img/landing/team/valentin.png" alt="" />
            <p class="name">Valentin Stancu</p>
            <span>Senior Software Dev</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-center">
      <div class="col-11">
        <div class="row justify-center">
          <div class="text-center profile col-lg-3">
            <img src="assets/img/landing/team/joanna.png" alt="" />
            <p class="name">Joanna Nemes</p>
            <span>Marketing Coordinator</span>
          </div>
          <div class="text-center profile col-lg-3">
            <img src="assets/img/landing/team/jorge.png" alt="" />
            <p class="name">Jorge Rodriguez</p>
            <span>Project Security</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="partners">
  <div class="container">
    <div class="row p-t-60">
      <div class="col-12">
        <a href="https://www.travala.com?ref=martinregan" target="_blank"
          ><img
            src="assets/img/landing/banner-2.png"
            alt="travala"
            class="img-fluid"
        /></a>
      </div>
    </div>
    <div class="row p-t-60">
      <div class="col-12 text-center">
        <h2>Partnerships and Affiliates</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="slider-partners">
          <a href="https://www.flowdesk.co/" target="_blank" rel="nofollow">
            <img src="assets/img/landing/partners/flowdesk.png" alt="" />
          </a>
          <a href="https://www.dextools.io/app/" target="_blank" rel="nofollow">
            <img
              class="small"
              src="assets/img/landing/partners/promoted_by_dext.png"
              alt=""
            />
          </a>
          <a
            href="https://www.autonio.foundation/"
            target="_blank"
            rel="nofollow"
          >
            <img
              class="small"
              src="assets/img/landing/partners/autonio-foundation.png"
              alt=""
            />
          </a>
          <a href="https://arkerlabs.com/" target="_blank" rel="nofollow">
            <img src="assets/img/landing/partners/arkerlabs.png" alt="" />
          </a>
        </div>
      </div>
      <div class="col-12">
        <div class="slider-partners">
          <a href="https://ctdsec.com/" target="_blank" rel="nofollow">
            <img
              class="small"
              src="assets/img/landing/partners/ctd-sec.png"
              alt=""
            />
          </a>
          <a href="https://www.xetacapital.com/" target="_blank" rel="nofollow">
            <img src="assets/img/landing/partners/xeta.svg" alt="" />
          </a>
          <a href="https://coinbound.io/" target="_blank" rel="nofollow">
            <img src="assets/img/landing/partners/coinbound.png" alt="" />
          </a>
          <a href="https://polygon.technology/" target="_blank" rel="nofollow">
            <img
              class="small"
              src="assets/img/landing/partners/matic.svg"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="social">
  <div class="container">
    <div class="row p-t-30">
      <div class="col-12 text-center">
        <h2>Say Aloha!</h2>
      </div>
    </div>
    <div class="row icon-social">
      <div class="col-12 text-center">
        <ul class="list-inline list-unstyled mt-3">
          <li class="list-inline-item">
            <a href="https://t.me/AlohaDeFi" target="_blank"
              ><i class="fa fa-telegram" aria-hidden="true"></i
            ></a>
          </li>
          <li class="list-inline-item">
            <a href="https://twitter.com/AlohaDefi" target="_blank"
              ><i class="fa fa-twitter" aria-hidden="true"></i
            ></a>
          </li>
          <li class="list-inline-item">
            <a href="https://github.com/Aloha-Open-Wifi" target="_blank"
              ><i class="fa fa-github" aria-hidden="true"></i
            ></a>
          </li>
          <li class="list-inline-item">
            <a
              href="https://www.linkedin.com/company/aloha-open-wifi/"
              target="_blank"
              ><i class="fa fa-linkedin" aria-hidden="true"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="buttons">
  <div class="container">
    <div class="row p-t-10">
      <div class="col-12 text-center">
        <h3>Powered by Polygon</h3>
      </div>
    </div>
  </div>
</section>
