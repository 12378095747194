<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <a href="/nft#current" (click)="selectedSeason = 5">
          <img src="/assets/img/nft/currentseason.png" class="zombie" alt="" />
        </a>
      </div>
    </div>

    <div class="row pd-t-20">
      <div class="col-sm-6 col-xs-6 col-lg-4 p-t-20 flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <a href="/nft#legacy" (click)="selectedSeason = 1">
              <img
                src="assets/img/buttons/seasons/1.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
          <div class="flip-card-back">
            <a href="/nft#legacy" (click)="selectedSeason = 1">
              <img
                src="assets/img/buttons/seasons/1.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xs-6 col-lg-4 p-t-20 flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <a href="/nft#legacy" (click)="selectedSeason = 2">
              <img
                src="assets/img/buttons/seasons/2.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
          <div class="flip-card-back">
            <a href="/nft#legacy" (click)="selectedSeason = 2">
              <img
                src="assets/img/buttons/seasons/2.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xs-6 col-lg-4 p-t-20 flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <a href="/nft#legacy" (click)="selectedSeason = 3">
              <img
                src="assets/img/buttons/seasons/3.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
          <div class="flip-card-back">
            <a href="/nft#legacy" (click)="selectedSeason = 3">
              <img
                src="assets/img/buttons/seasons/3.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xs-6 col-lg-4 p-t-20 flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <a href="/nft#legacy" (click)="selectedSeason = 4">
              <img
                src="assets/img/buttons/seasons/4.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
          <div class="flip-card-back">
            <a href="/nft#legacy" (click)="selectedSeason = 4">
              <img
                src="assets/img/buttons/seasons/4.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xs-6 col-lg-4 p-t-20 flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <a href="/nft#legacy" (click)="selectedSeason = 5">
              <img
                src="assets/img/buttons/seasons/5.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
          <div class="flip-card-back">
            <a href="/nft#legacy" (click)="selectedSeason = 5">
              <img
                src="assets/img/buttons/seasons/5.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xs-6 col-lg-4 p-t-20 flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <a href="/nft#legacy" (click)="selectedSeason = 6">
              <img
                src="assets/img/buttons/seasons/6.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
          <div class="flip-card-back">
            <a href="/nft#legacy" (click)="selectedSeason = 6">
              <img
                src="assets/img/buttons/seasons/6.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-xs-6 col-lg-4 p-t-20 flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <a href="/nft#legacy" (click)="selectedSeason = 7">
              <img
                src="assets/img/buttons/seasons/7.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
          <div class="flip-card-back">
            <a href="/nft#legacy" (click)="selectedSeason = 7">
              <img
                src="assets/img/buttons/seasons/7.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-xs-6 col-lg-4 p-t-20 flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <a href="/nft#legacy" (click)="selectedSeason = 8">
              <img
                src="assets/img/buttons/seasons/8.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
          <div class="flip-card-back">
            <a href="/nft#legacy" (click)="selectedSeason = 8">
              <img
                src="assets/img/buttons/seasons/8.png"
                alt=""
                class="img-fluid"
              />
            </a>
          </div>
        </div>
      </div>

    </div>

    <div class="row justify-center p-t-30">
      <div class="col-lg-2 justify-center">
        <div class="image-card">
          <img src="assets/img/nft/rare.png" alt="" />
        </div>
      </div>
      <div class="col-lg-2 justify-center">
        <div class="image-card">
          <img src="assets/img/nft/ultra-rare.png" alt="" />
        </div>
      </div>
      <div class="col-lg-2 justify-center">
        <div class="image-card">
          <img src="assets/img/nft/exclusive.png" alt="" />
        </div>
      </div>
    </div>

    <div class="row justify-center">
      <div class="col-lg-10 center">
        <div class="normal-txt p-b-30">
          <span>
            The Aloha Non-Fungible Tokens. There will be a themed season
            released each month, with all 6 characters taking part. Limited
            editions to each season – making all NFT’s rare (especially as we
            grow). Special editions for partnerships will be released to
            celebrate each collaboration. Meet the Ambassadors.
          </span>
        </div>
      </div>
    </div>

    <div class="row justify-center">
      <div class="col-lg-10 center">
        <div class="more-txt p-b-30">
          <a href="/nft#meet"><span> Read More </span></a>
          <div class="bullet"></div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="background-2" id="meet">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 center p-title">
        <span class="title">Meet Our Characters</span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-2 col-sm-4 p-b-30">
        <div class="faces bodhi" (click)="selectedCharacter = 0"></div>
      </div>
      <div class="col-lg-2 col-sm-4 p-b-30">
        <div class="faces bambi" (click)="selectedCharacter = 1"></div>
      </div>
      <div class="col-lg-2 col-sm-4 p-b-30">
        <div class="faces jin" (click)="selectedCharacter = 2"></div>
      </div>
      <div class="col-lg-2 col-sm-4 p-b-30">
        <div class="faces jazz" (click)="selectedCharacter = 3"></div>
      </div>
      <div class="col-lg-2 col-sm-4 p-b-30">
        <div class="faces duke" (click)="selectedCharacter = 4"></div>
      </div>
      <div class="col-lg-2 col-sm-4 p-b-30">
        <div class="faces daisy" (click)="selectedCharacter = 5"></div>
      </div>
    </div>

    <div class="row p-t-faces">
      <div class="col-lg-3 align-center">
        <div
          class="card-faces"
          *ngIf="selectedCharacter == 0"
          style="background-image: url(/assets/img/cards/full/1_1.png)"
        ></div>
        <div
          class="card-faces"
          *ngIf="selectedCharacter == 1"
          style="background-image: url(/assets/img/cards/full/6_1.png)"
        ></div>
        <div
          class="card-faces"
          *ngIf="selectedCharacter == 2"
          style="background-image: url(/assets/img/cards/full/5_1.png)"
        ></div>
        <div
          class="card-faces"
          *ngIf="selectedCharacter == 3"
          style="background-image: url(/assets/img/cards/full/4_1.png)"
        ></div>
        <div
          class="card-faces"
          *ngIf="selectedCharacter == 4"
          style="background-image: url(/assets/img/cards/full/3_1.png)"
        ></div>
        <div
          class="card-faces"
          *ngIf="selectedCharacter == 5"
          style="background-image: url(/assets/img/cards/full/2_1.png)"
        ></div>
      </div>
      <div class="col-lg-9" *ngIf="selectedCharacter == 0">
        <div class="row">
          <div class="col-lg-12 p-tb-faces">
            <span class="title-faces">“LIVE LIFE TO THE MAX!”</span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              Bodhi is one of those larger than life characters who was always
              the popular kid at school. Laid back and friendly, he is always
              the life and soul of the party, and there’s nothing he loves more
              than the ocean and its swell, wind, weather and tides.
            </span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              Born in Australia Bodhi grew up surfing, and from a young age he
              was always encouraged to excel. With his daring spirit and “no
              limits” attitude he easily progressed to becoming a professional
              surfer who has competed around the world.
            </span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              Bodhi is a travelling adventurer who easily makes new friends
              wherever he goes. With his brilliance and charm there’s nothing to
              stop him, and a day riding the waves doesn’t mean he can’t find
              time to keep track of his crypto profits back on the beach.
            </span>
          </div>
        </div>
      </div>

      <div class="col-lg-9" *ngIf="selectedCharacter == 1">
        <div class="row">
          <div class="col-lg-12 p-tb-faces">
            <span class="title-faces">“SAY IT AS IT IS!”</span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              Bambi is the life and soul of the party. She’s fun, bubbly, loves
              to dance and is driven by a strong personality. Don’t
              underestimate her, she is a formidable force who plays by her own
              rules. She’ll take charge in any situation and is a natural
              leader.
            </span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              Born in Cape Town, she grew up with a love and respect for the
              ocean and marine wildlife, and is a hard-core supporter of marine
              ecology. Her passion is surfing, and she lives to brave the big
              waves with her friends.
            </span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              Juggling a day job with study, Bambi knows it’s important to keep
              her cool, hold her head high and recognises she can turn to the
              surf if the going gets tough. Her mantra is: ‘Say it as it is’.
              There’s so much to experience in life, no point in hanging around
              and missing an opportunity?
            </span>
          </div>
        </div>
      </div>

      <div class="col-lg-9" *ngIf="selectedCharacter == 2">
        <div class="row">
          <div class="col-lg-12 p-tb-faces">
            <span class="title-faces">“FEEL ALIVE!”</span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              Jin is the ultimate daring spirit and there’s more to him than
              meets the eye. When he was 4 years old, Jin moved from China to
              California where he first learned to surf. Speed is his watch word
              and he’s passionate about riding the waves.
            </span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              Confident, evolving and tireless, Jin now lives in Hawaii,
              arguably one of the best surfing destinations in the world where
              he practices all year round.
            </span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              With his skills and determination there’s nothing to stop him, and
              he is your perfect partner for a surfing adventure.
            </span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              He’s the kind of friend who’s hard to keep up with, and however
              far-out and extreme his pursuit of adrenaline and excitement, one
              thing is certain, hanging with Jin will always always make you
              feel alive.
            </span>
          </div>
        </div>
      </div>

      <div class="col-lg-9" *ngIf="selectedCharacter == 3">
        <div class="row">
          <div class="col-lg-12 p-tb-faces">
            <span class="title-faces">“KNOW WHAT YOU WANT”</span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              Jazz is smart, savvy and bold. She knows what she wants and is
              fully prepared to go after it. Born in Sri Lanka, Jazz spent her
              childhood on the south beaches of Hikkaduwa where she developed
              her love for the ocean.
            </span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              A self-made woman, she’s always looking for new opportunity and
              adventure, and with a degree in Marine Biology, Jazz knows she
              could be equally successful in business as she is on the
              surfboard.
            </span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              A demon surfer and fierce networker, who she doesn’t know really
              isn’t worth knowing, and when isn’t online managing her crypto
              investments, she is either working, partying or shopping.
            </span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              With a strong “you only live once” attitude, she’s not afraid to
              spend her crypto profits in pursuit of living life to the max.
            </span>
          </div>
        </div>
      </div>

      <div class="col-lg-9" *ngIf="selectedCharacter == 4">
        <div class="row">
          <div class="col-lg-12 p-tb-faces">
            <span class="title-faces"
              >“THERE’S MORE OUT THERE THAN WE KNOW”</span
            >
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              The youngest of the Aloha family is Duke and he’s fearless. Duke
              loves life with a passion but always puts his friends and family
              first. Loyal, witty and sometimes a little naïve, Duke lives for
              the rhythm of sound and surf.
            </span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              A successful international DJ, he still grabs every opportunity to
              trade the decks for a surfboard, cutting up the biggest kick ass
              waves.
            </span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              Born in Brazil, Duke rents an apartment in Rio where he lives life
              free from worries, with a strong belief that you can do pretty
              much anything if you put your mind to it.
            </span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              Duke is a born entrepreneur, and with the world already pretty
              much at his fingertips, he still feels there is so much more out
              there and has a thirst for learning, exploring and developing
              between time spent with his music and the waves.
            </span>
          </div>
        </div>
      </div>

      <div class="col-lg-9" *ngIf="selectedCharacter == 5">
        <div class="row">
          <div class="col-lg-12 p-tb-faces">
            <span class="title-faces">“STAND UP FOR WHAT YOU BELIEVE IN”</span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              Growing up, Daisy has always been interested in nature, and with a
              deep appreciation of everything around her, she’s a passionate
              campaigner and is very mindful of the world she lives in and where
              she fits in.
            </span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              Born in Stockholm, Daisy loves to spend her summers alongside the
              big wave experts and surfing community in El Tunco, El Salvador.
            </span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              She fervently supports the environment and volunteers with a
              wildlife conservation NGO. Her special focus is protecting the
              marine turtles nesting in El Salvador.
            </span>
          </div>
          <div class="col-lg-12 p-b-30">
            <span class="desc-faces">
              Daisy is not afraid to stand up for what she believes in, and is
              quick to defend her friends and anybody who shares her beliefs.
              Once an ally, Daisy is a friend for life and will always bring fun
              and positivity into any social circle.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="current">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 center p-title">
        <span class="title">Current Season NFT’s</span>
      </div>
    </div>

    <div class="row gallery">
      <div class="col-lg-2">
        <div class="cards card-1 zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-2 zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-3 zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-4 zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-5 zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-6 zoom"></div>
      </div>
    </div>

    <div class="row p-b-30">
      <div class="col-lg-12 center">
        <a [routerLink]="['/pool/2']">
          <div class="btn-image earn"></div>
        </a>
      </div>
    </div>
  </div>
</section>

<section class="background-2" id="legacy">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 center p-title">
        <span class="title">Legacy Seasons</span>
      </div>
    </div>

    <div class="row gallery" *ngIf="selectedSeason == 1">
      <div class="col-lg-2">
        <div class="cards card-1-surf zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-2-surf zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-3-surf zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-4-surf zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-5-surf zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-6-surf zoom"></div>
      </div>
    </div>

    <div class="row gallery" *ngIf="selectedSeason == 2">
      <div class="col-lg-2">
        <div class="cards card-1-retro zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-2-retro zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-3-retro zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-4-retro zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-5-retro zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-6-retro zoom"></div>
      </div>
    </div>

    <div class="row gallery" *ngIf="selectedSeason == 3">
      <div class="col-lg-2">
        <div class="cards card-1-titans zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-2-titans zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-3-titans zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-4-titans zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-5-titans zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-6-titans zoom"></div>
      </div>
    </div>

    <div class="row gallery" *ngIf="selectedSeason == 4">
      <div class="col-lg-2">
        <div class="cards card-1-zombie zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-2-zombie zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-3-zombie zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-4-zombie zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-5-zombie zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-6-zombie zoom"></div>
      </div>
    </div>

    <div class="row gallery" *ngIf="selectedSeason == 5">
      <div class="col-lg-2">
        <div class="cards card-1-pool zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-2-pool zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-3-pool zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-4-pool zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-5-pool zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-6-pool zoom"></div>
      </div>
    </div>

    <div class="row gallery" *ngIf="selectedSeason == 6">
      <div class="col-lg-2">
        <div class="cards card-1-cult zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-2-cult zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-3-cult zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-4-cult zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-5-cult zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-6-cult zoom"></div>
      </div>
    </div>

    <div class="row gallery" *ngIf="selectedSeason == 7">
      <div class="col-lg-2">
        <div class="cards card-1-halloween zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-2-halloween zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-3-halloween zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-4-halloween zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-5-halloween zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-6-halloween zoom"></div>
      </div>
    </div>

    <div class="row gallery" *ngIf="selectedSeason == 8">
      <div class="col-lg-2">
        <div class="cards card-1-matrix zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-2-matrix zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-3-matrix zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-4-matrix zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-5-matrix zoom"></div>
      </div>
      <div class="col-lg-2">
        <div class="cards card-6-matrix zoom"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <a [routerLink]="['/stake']">
          <div class="btn-image startStake"></div>
        </a>
      </div>
    </div>

    <div class="row p-b-30">

      <div class="col-lg-4 p-b-30">
        <div class="row center">
          <div class="col-lg-12">
            <span class="season-txt">Season 8: </span>
          </div>
          <div class="col-lg-12">
            <span class="season-sub-txt">Matrix</span>
          </div>
          <div class="col-lg-12">
            <img src="assets/img/nft/deck-8.png" class="deck" alt="" />
          </div>
          <div class="col-lg-12">
            <a href="/nft#legacy" (click)="selectedSeason = 8">
              <div class="btn-image viewNow"></div>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 p-b-30">
        <div class="row center">
          <div class="col-lg-12">
            <span class="season-txt">Season 7: </span>
          </div>
          <div class="col-lg-12">
            <span class="season-sub-txt">Halloween Party</span>
          </div>
          <div class="col-lg-12">
            <img src="assets/img/nft/deck-7.png" class="deck" alt="" />
          </div>
          <div class="col-lg-12">
            <a href="/nft#legacy" (click)="selectedSeason = 7">
              <div class="btn-image viewNow"></div>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 p-b-30">
        <div class="row center">
          <div class="col-lg-12">
            <span class="season-txt">Season 6: </span>
          </div>
          <div class="col-lg-12">
            <span class="season-sub-txt">Cult Friction</span>
          </div>
          <div class="col-lg-12">
            <img src="assets/img/nft/deck-6.png" class="deck" alt="" />
          </div>
          <div class="col-lg-12">
            <a href="/nft#legacy" (click)="selectedSeason = 6">
              <div class="btn-image viewNow"></div>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 p-b-30">
        <div class="row center">
          <div class="col-lg-12">
            <span class="season-txt">Season 5: </span>
          </div>
          <div class="col-lg-12">
            <span class="season-sub-txt">Pool Party</span>
          </div>
          <div class="col-lg-12">
            <img src="assets/img/nft/deck-5.png" class="deck" alt="" />
          </div>
          <div class="col-lg-12">
            <a href="/nft#legacy" (click)="selectedSeason = 5">
              <div class="btn-image viewNow"></div>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 p-b-30">
        <div class="row center">
          <div class="col-lg-12">
            <span class="season-txt">Season 4: </span>
          </div>
          <div class="col-lg-12">
            <span class="season-sub-txt">Zombie</span>
          </div>
          <div class="col-lg-12">
            <img src="assets/img/nft/deck-4.png" class="deck" alt="" />
          </div>
          <div class="col-lg-12">
            <a href="/nft#legacy" (click)="selectedSeason = 4">
              <div class="btn-image viewNow"></div>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 p-b-30">
        <div class="row center">
          <div class="col-lg-12">
            <span class="season-txt">Season 3: </span>
          </div>
          <div class="col-lg-12">
            <span class="season-sub-txt">Iron Titans</span>
          </div>
          <div class="col-lg-12">
            <img src="assets/img/nft/deck-3.png" class="deck" alt="" />
          </div>
          <div class="col-lg-12">
            <a href="/nft#legacy" (click)="selectedSeason = 3">
              <div class="btn-image viewNow"></div>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 p-b-30">
        <div class="row center">
          <div class="col-lg-12">
            <span class="season-txt">Season 2: </span>
          </div>
          <div class="col-lg-12">
            <span class="season-sub-txt">Retro Sci-Fi</span>
          </div>
          <div class="col-lg-12">
            <img src="assets/img/nft/deck-2.png" class="deck" alt="" />
          </div>
          <div class="col-lg-12">
            <a href="/nft#legacy" (click)="selectedSeason = 2">
              <div class="btn-image viewNow"></div>
            </a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 p-b-30">
        <div class="row center">
          <div class="col-lg-12">
            <span class="season-txt">Season 1: </span>
          </div>
          <div class="col-lg-12">
            <span class="season-sub-txt">Surf’s Up!</span>
          </div>
          <div class="col-lg-12">
            <img src="assets/img/nft/deck-1.png" class="deck" alt="" />
          </div>
          <div class="col-lg-12">
            <a href="/nft#legacy" (click)="selectedSeason = 1">
              <div class="btn-image viewNow"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="partnership">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 center p-title">
        <span class="title">Partnership NFTs</span>
      </div>
    </div>

    <div class="row p-b-30">
      <div class="col-lg-4 p-b-30">
        <div class="row center">
          <div class="col-lg-12">
            <span class="season-txt">Aloha & NIOX </span>
          </div>
          <div class="col-lg-12">
            <span class="season-sub-txt">Iron Titan</span>
          </div>
          <div class="col-lg-12">
            <span class="season-sub-txt">(Static)</span>
          </div>
          <div class="col-lg-12">
            <img src="assets/img/nft/example-1.png" class="deck" alt="" />
          </div>
        </div>
      </div>

      <div class="col-lg-4 p-b-30">
        <div class="row center">
          <div class="col-lg-12">
            <span class="season-txt">Aloha & NIOX </span>
          </div>
          <div class="col-lg-12">
            <span class="season-sub-txt">Iron Titan</span>
          </div>
          <div class="col-lg-12">
            <span class="season-sub-txt">(Animated)</span>
          </div>
          <div class="col-lg-12">
            <img src="assets/img/nft/example-1.png" class="deck" alt="" />
          </div>
        </div>
      </div>

      <div class="col-lg-4 p-b-30">
        <div class="row center">
          <div class="col-lg-12">
            <span class="season-txt">Aloha & NIOX </span>
          </div>
          <div class="col-lg-12">
            <span class="season-sub-txt">Escape from the</span>
          </div>
          <div class="col-lg-12">
            <span class="season-sub-txt">Planet of the Dapps</span>
          </div>
          <div class="col-lg-12">
            <img src="assets/img/nft/example-2.png" class="deck" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
