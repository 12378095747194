<div class="slim-mainpanel pd-b-40">
  <div class="container">
    <div class="row row-xs justify-content-md-center">
      <div class="col-lg-12 pd-lg-t-40">
        <div class="row custom-padding justify-content-center">
          <div class="col-lg-12 farm-title pd-b-20">
            <img
              class="hg-50"
              src="assets/img/cards/rank/vip.png"
              alt="img-mobile"
            />
            <img
              class="pd-l-20 hg-50"
              src="assets/img/cards/rank/ultra-rare.png"
              alt="img-mobile"
            />
            <img
              class="pd-l-20 hg-50"
              src="assets/img/cards/rank/rare.png"
              alt="img-mobile"
            />
          </div>
          <div class="col-lg-12 farm-title title-vip txt-line-vip">
            <span>Aloha NFT #{{ id }}</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="loading" class="row justify-content-md-center pd-t-20">
      <div class="col-md-12 col-xl-12">
        <div class="d-flex ht-300 pos-relative align-items-center">
          <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!loading">
      <div class="row justify-content-md-center pd-t-20">
        <div class="col-sm-12 col-md-6 col-lg-4 pd-b-20">
          <app-aloha-card
            image="{{ image }}"
            rarity="{{ rarity }}"
            background="{{ background }}"
          ></app-aloha-card>
        </div>

        <div class="col-sm-6 col-lg-8 mg-t-10 mg-sm-t-10">
          <div class="card card-status">
            <div class="row txt-center">
              <div class="col-lg-12">
                <p class="txt-card-title">- Token -</p>
                <p class="txt-card-info">#{{ id }}</p>
              </div>
            </div>
            <div class="col-lg-12 underline"></div>
            <div class="row txt-center pd-t-20">
              <div class="col-lg-12">
                <p class="txt-card-title">- Image -</p>
                <p class="txt-card-info">#{{ image }}</p>
              </div>
            </div>
            <div class="col-lg-12 underline"></div>

            <div class="row txt-center pd-t-20">
              <div class="col-lg-12">
                <p class="txt-card-title">- Border -</p>
                <p class="txt-card-info">#{{ background }}</p>
              </div>
            </div>
            <div class="col-lg-12 underline"></div>

            <div class="row txt-center pd-t-20">
              <div class="col-lg-12">
                <p class="txt-card-title">- Series -</p>
                <p class="txt-card-info">#{{ serie }}</p>
              </div>
            </div>
            <div class="col-lg-12 underline"></div>

            <div class="row txt-center pd-t-20">
              <div class="col-lg-12">
                <p class="txt-card-title">- Rarity -</p>
                <p class="txt-card-info">
                  #{{ rarity }} [<span *ngIf="rarity == 1">Rare</span
                  ><span *ngIf="rarity == 2">Ultra Rare</span
                  ><span *ngIf="rarity == 3">Exclusive VIP</span>]
                </p>
              </div>
            </div>
            <div class="col-lg-12 underline"></div>

            <div class="row txt-center pd-t-20">
              <div class="col-lg-12">
                <p class="txt-card-title">- Owner -</p>
                <p class="txt-card-info">
                  <a
                    href="https://etherscan.io/address/{{ owner }}"
                    target="BLANK"
                    *ngIf="aloha.connectedToRootChain"
                    >{{ owner }}</a
                  >
                </p>
                <p class="txt-card-info">
                  <a
                    href="https://explorer-mainnet.maticvigil.com/address/{{
                      owner
                    }}"
                    target="BLANK"
                    *ngIf="!aloha.connectedToRootChain"
                    >{{ owner }}</a
                  >
                </p>
              </div>
            </div>
            <div class="col-lg-12 underline"></div>

            <div class="row txt-center pd-t-20" *ngIf="this.owner === account">
              <div class="col-lg-12">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="0x0"
                    #addressTo
                    required
                  />
                  <button class="btn btn-aloha" (click)="transfer(id)">
                    TRANSFER TO ADDRESS
                  </button>
                </div>
              </div>
            </div>
            <div
              class="col-lg-12 underline"
              *ngIf="this.owner === account"
            ></div>

            <div class="row txt-center pd-t-20" *ngIf="this.owner === account">
              <div class="col-lg-12">
                <p class="txt-card-info">
                  <button
                    class="btn btn-oblong btn-aloha btn-block"
                    (click)="allowERC721OnRoot()"
                    *ngIf="network == 'ETH' && !allowanceERC721Root"
                  >
                    APPROVE TRANSFER TO MATIC
                  </button>
                  <button
                    class="btn btn-oblong btn-aloha btn-block"
                    (click)="transferERC721(id)"
                    *ngIf="network == 'ETH' && allowanceERC721Root"
                  >
                    TRANSFER TO MATIC
                  </button>
                  <button
                    class="btn btn-oblong btn-aloha btn-block"
                    (click)="allowERC721OnMatic(id)"
                    *ngIf="network == 'MATIC' && !allowanceERC721Matic"
                  >
                    APPROVE TRANSFER TO ETHEREUM
                  </button>
                  <button
                    class="btn btn-oblong btn-aloha btn-block"
                    (click)="transferERC721(id)"
                    *ngIf="network == 'MATIC' && allowanceERC721Matic"
                  >
                    TRANSFER TO ETHEREUM
                  </button>
                </p>
              </div>
            </div>
            <div
              class="col-lg-12 underline"
              *ngIf="this.owner === account"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
